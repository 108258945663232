import BigNumber from 'bignumber.js';
import { formatEther, parseEther } from '@ethersproject/units';
import { format } from 'src/utils';

export const checkApprove = async (contract: any, userAddress: string, poolContractAddr: string) => {
    let isApproved = false;
    try {
        const allowance = await contract.allowance(userAddress, poolContractAddr);
        const userAllowance = new BigNumber(formatEther(allowance)).toNumber();
        isApproved = userAllowance !== 0;
    } catch (e) {
        console.log('checkApprove error: ', e);
    }
    return isApproved;
};

export const checkApproveTopUp = async (contract: any, userAddress: string, poolContractAddr: string) => {
    let isApprovedTopUp = false;
    try {
        isApprovedTopUp = await contract.isApprovedForAll(userAddress, poolContractAddr);
    } catch (e) {
        console.log('checkApproveTopUp error: ', e);
    }
    return isApprovedTopUp;
};

export const handleApprove = async (contract: any, poolContractAddr: string, approveAmount: number) => {
    try {
        let _approveAmount: any = approveAmount;
        if (!approveAmount) {
            _approveAmount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
        }
        return await contract.approve(poolContractAddr, _approveAmount);
    } catch (e) {
        console.log('handleApprove error: ', e);
        throw e;
    }
};
export const handleApproveTopUp = async (contract: any, poolContractAddr: string, approveAmount: number) => {
    try {
        return await contract.setApprovalForAll(poolContractAddr, true);
    } catch (e) {
        console.log('handleApprove error: ', e);
        throw e;
    }
};

export const handleGetReward = async (contract: any, userAddress: string): Promise<number> => {
    let userEarned = 0;
    try {
        let stakerReward = await contract.stakers(userAddress);
        stakerReward = new BigNumber(formatEther(stakerReward[2])).toNumber();

        const earned = await contract.getCurrentReward(userAddress);
        userEarned = new BigNumber(formatEther(earned)).toNumber();

        const total = userEarned + stakerReward;

        userEarned = format.amount(total) as any;
    } catch (e) {
        console.log('handleGetReward error: ', e);
    }
    return userEarned;
};

export const handleGetBalance = async (contract: any, userAddress: string): Promise<number> => {
    let userBalance = 0;
    try {
        const balance = await contract.balanceOf(userAddress);
        userBalance = new BigNumber(formatEther(balance)).toNumber();
    } catch (e) {
        console.log('handleGetBalance error: ', e);
    }
    return userBalance;
};

export const handleGetStakingBalance = async (contract: any, userAddress: string): Promise<number> => {
    let userStakeBalance = 0;
    try {
        const stakeBalance = await contract.stakers(userAddress);
        userStakeBalance = new BigNumber(formatEther(stakeBalance[0])).toNumber();
    } catch (e) {
        console.log('handleGetStakingBalance error: ', e);
    }
    return userStakeBalance;
};

export const handleGetStakingFirstStakeTime = async (contract: any, userAddress: string): Promise<number> => {
    let firstStakeTime = 0;
    try {
        const stakers = await contract.stakers(userAddress);
        firstStakeTime = stakers[5].toNumber();
    } catch (e) {
        console.log('handleGetStakingFirstStakeTime error: ', e);
    }
    return firstStakeTime;
};

export const handleGetStakingNft = async (contract: any): Promise<number[]> => {
    let userStakeNft = [];
    try {
        const stakeNfts = await contract.getStakedBonyNfts();
        userStakeNft = stakeNfts.map((stakeNft: any) => stakeNft.toNumber());
    } catch (e) {
        console.log('handleGetStakingNft error: ', e, contract);
    }
    return userStakeNft;
};

export const handleGetStakingNftApr = async (contract: any, userAddress: string): Promise<number> => {
    let userStakeNftApr = 0;
    try {
        const result = await contract.stakers(userAddress);
        const stakeNftApr = result[1].toNumber();
        userStakeNftApr = stakeNftApr / 100;
        userStakeNftApr = format.amount(userStakeNftApr, 0) as any;
    } catch (e) {
        console.log('handleGetStakingNftApr error: ', e);
    }
    return userStakeNftApr;
};

export const handleGetStakingPool = async (contract: any, contractAddress: string): Promise<number> => {
    let stakingPoolAmount = 0;
    try {
        const stakeBalance = await contract.methods.totalBalanceLocked().call();
        stakingPoolAmount = new BigNumber(formatEther(stakeBalance)).toNumber();
    } catch (e) {
        console.log('handleGetStakingPool error: ', e);
    }
    return stakingPoolAmount;
};

export const handleGetStakingTierAPY = async (contract: any, address: string): Promise<number> => {
    let tierAPR = 0;
    try {
        const currentAPR = await contract.getCurrentAPR(address);
        tierAPR = currentAPR.toNumber() / 100;
        tierAPR = format.amount(tierAPR, 0) as any;
    } catch (e) {
        console.log('handleGetStakingTierAPY error: ', e);
    }
    return tierAPR;
};
