import { IRootState } from 'src/redux/interface';
import { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { actionGetPoolApprovedDetails } from 'src/modules/WhiteList/redux/WhiteList.action';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import { actionGetProfileInfo } from 'src/modules/Accounts/redux/Account.action';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { whiteListUserDetailsSelector } from 'src/modules/WhiteList/WhiteList.selector';
import { ACTION_TYPES } from './Auth.constant';
import { IForgotState, ISigninState, ISignupState, IUserAuth, IVerifyState } from './Auth.type';
import { forgotSelector, loadingSelector, signinSelector, signupSelector } from './Auth.selector';
import {
    apiForgotSubmitCode,
    apiReSendVerifyEmail,
    apiForgotSubmitEmail,
    apiLogin,
    apiSignup,
    apiVerifyEmail,
} from './Auth.service';

export const actionSetSignin = (payload: ISigninState) => ({
    type: ACTION_TYPES.SIGNIN,
    payload,
});

export const actionSetSignup = (payload: ISignupState) => ({
    type: ACTION_TYPES.SIGNUP,
    payload,
});

export const actionSetForgot = (payload: IForgotState) => ({
    type: ACTION_TYPES.FORGOT,
    payload,
});

export const actionSigninSuccess = (payload: IUserAuth) => ({
    type: ACTION_TYPES.SIGNIN_SUCCESS,
    payload,
});

export const actionLogout = () => ({
    type: ACTION_TYPES.LOGOUT,
});

export const actionFree = () => ({
    type: ACTION_TYPES.FREE,
});

export const actionSetLoading = ({ loading }: { loading: boolean }) => ({
    type: ACTION_TYPES.LOADING,
    payload: { loading },
});

export const actionSetVerify = (payload: IVerifyState) => ({
    type: ACTION_TYPES.VERIFY,
    payload,
});

export const actionSignin = () => async (dispatch: Dispatch<any>, getState: () => IRootState) => {
    try {
        const state = getState();
        const { email, password } = signinSelector(state);
        const loading = loadingSelector(state);
        const whiteList = whiteListUserDetailsSelector(state);
        if (!email || !password || loading) return;
        dispatch(actionSetLoading({ loading: true }));
        const { token } = await apiLogin({ email, password });
        dispatch(
            actionSigninSuccess({
                email,
                accessToken: token,
            }),
        );

        if (whiteList?.WhiteListName) {
            dispatch(actionGetPoolApprovedDetails(whiteList?.WhiteListName, token));
        }
        dispatch(actionGetProfileInfo(0, token));
        await dispatch(actionSetLoading({ loading: false }));
    } catch (e) {
        await dispatch(actionSetLoading({ loading: false }));
        throw e;
    }
};

export const actionSignup = () => async (dispatch: Dispatch<any>, getState: () => IRootState) => {
    try {
        const state = getState();
        const { email, password, confirmPassword } = signupSelector(state);
        const loading = loadingSelector(state);
        if (!email || !password || !confirmPassword || loading) return;
        dispatch(actionSetLoading({ loading: true }));
        await apiSignup({ email, password, confirmPassword });
        dispatch(actionSetLoading({ loading: false }));
    } catch (e) {
        dispatch(actionSetLoading({ loading: false }));
        throw e;
    }
};

export const actionVerify = (token: string) => async (dispatch: Dispatch<any>, getState: () => IRootState) => {
    try {
        const state = getState();
        const loading = loadingSelector(state);
        if (loading) return;
        dispatch(actionSetLoading({ loading: true }));
        await apiVerifyEmail({ token });
        dispatch(actionSetLoading({ loading: false }));
    } catch (e) {
        dispatch(actionSetLoading({ loading: false }));
        throw e;
    }
};

export const actionResendVerifyEmail =
    (token: string) => async (dispatch: Dispatch<any>, getState: () => IRootState) => {
        try {
            const state = getState();
            const loading = loadingSelector(state);
            if (loading) return;
            dispatch(actionSetLoading({ loading: true }));
            const data = await apiReSendVerifyEmail({ token });
            if (data.result) {
                dispatch(
                    actionToggleModal({
                        visible: false,
                        data: null,
                    }),
                );
                dispatch(
                    addToast({
                        type: TOAST_TYPES.SUCCESS,
                        title: 'Success',
                        description: 'An email has been sent to your inbox, please check.',
                    }),
                );
            }
            dispatch(actionSetLoading({ loading: false }));
        } catch (e) {
            dispatch(actionSetLoading({ loading: false }));
            throw e;
        }
    };

export const actionForgotSubmitEmail = () => async (dispatch: Dispatch<any>, getState: () => IRootState) => {
    try {
        const state = getState();
        const loading = loadingSelector(state);
        const { email } = forgotSelector(state);
        if (loading || !email) return;
        dispatch(actionSetLoading({ loading: true }));
        await apiForgotSubmitEmail({ email });
        dispatch(actionSetLoading({ loading: false }));
    } catch (e) {
        dispatch(actionSetLoading({ loading: false }));
        throw e;
    }
};

export const actionForgotSubmitCode = () => async (dispatch: Dispatch<any>, getState: () => IRootState) => {
    try {
        const state = getState();
        const loading = loadingSelector(state);
        const { pass, code, confirmPass } = forgotSelector(state);
        if (loading || !pass || !code || !confirmPass) return;
        dispatch(actionSetLoading({ loading: true }));
        await apiForgotSubmitCode({ code, newPassword: pass, confirmNewPassword: confirmPass });
        dispatch(actionSetLoading({ loading: false }));
    } catch (e) {
        dispatch(actionSetLoading({ loading: false }));
        throw e;
    }
};
