import http from 'src/services/http';
import { camelCaseKeys } from 'src/utils/camelcase';
import { ENVS } from '../../../configs/Configs.env';

export const apiGetPoolApprovedByID = async (poolID: any, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}pool-approved/${poolID}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetPoolApprovedDetails = async (projectName: any, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}whitelist/detail/${projectName}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateUserWhiteList = async (data: any, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}whitelist`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
