import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { isLoginSelector, userInfoSelector } from 'src/modules/Auth/Auth.selector';
import { apiReSendVerifyEmail } from 'src/modules/Auth/Auth.service';
import { actionGetProfileInfo, actionUpdateKycRefIdToProfile } from 'src/modules/Accounts/redux/Account.action';
import { ReactComponent as IconChecked } from 'src/assets/svg/checked.svg';
import { ENVS } from 'src/configs/Configs.env';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { BTNPrimary } from '../../../components/Button';

declare global {
    interface Window {
        BlockpassKYCConnect?: any;
    }
}
const Account = React.memo(() => {
    const userInfoSe = useSelector(userInfoSelector);
    let token = userInfoSe.auth.accessToken;
    const { userInfo } = useSelector((state: any) => {
        return state.account;
    });
    const dispatch = useDispatch();
    const fetchUsers = () => {
        dispatch(actionGetProfileInfo(1, token));
    };
    const clientId = `${process.env.REACT_APP_BLOCK_PASS_CLIENT_ID}`;
    const refId = `${clientId}_${userInfo?.ID}`;
    React.useEffect(() => {
        fetchUsers();
        const blockPass = new window.BlockpassKYCConnect(clientId, {
            refId,
            email: userInfo.Email,
        });
        blockPass.startKYCConnect();

        blockPass.on('KYCConnectSuccess', () => {
            dispatch(actionUpdateKycRefIdToProfile(refId, token));
        });
        blockPass.on('KYCConnectLoad', () => {
            console.log('KYCConnectLoad');
        });
        blockPass.on('KYCConnectCancel', () => {
            console.log('Cancelled!');
        });
    }, []);

    const isVerifiedEmail = userInfo?.IsVerifiedEmail;
    const verifyHandler = () => {
        if (isVerifiedEmail) {
            return <IconChecked />;
        }
    };

    let buttonText = 'Checking...';
    let className = 'kyc-button ';
    let kycStatus = userInfo?.KYCStatus;
    let kycData = userInfo?.KYCData?.data;

    if (kycStatus === 0) {
        buttonText = 'Verify now';
    } else if (kycStatus === 1) {
        if (kycData?.status === 'waiting') {
            buttonText = 'Waiting';
            className += 'warning';
        } else if (kycData?.status === 'rejected') {
            buttonText = 'Rejected';
            className += 'rejected';
        } else {
            buttonText = kycData?.status !== '' ? kycData?.status : 'Error';
        }
    } else if (kycStatus === 2) {
        buttonText = 'Verified';
        className += 'success';
    } else if (kycStatus === 3) {
        buttonText = 'Failed';
        className += 'rejected';
    }
    let isDisabled = false;
    if (kycStatus === 2 || kycStatus === 3 || kycStatus === -1) {
        isDisabled = true;
    }

    return (
        <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }}>
                <Row className="row-content">
                    <div className="account">
                        <div className="account">
                            <span className="is-mobile header-title">Account</span>
                            <Row>
                                <Col xs={4} md={24} className="fs-large fw-bold email">
                                    Email
                                </Col>
                                <Col xs={20} md={24} className="fs-large fw-bold email">
                                    {userInfo.Email} <span style={{ margin: '0 0 0 10px' }}>{verifyHandler()}</span>
                                </Col>
                            </Row>
                            {/* <span className="fs-large fw-bold">Email</span>
                        <span className="fs-large fw-bold">
                            {userInfo.Email} <span style={{ margin: '0 0 0 10px' }}>{verifyHandler()}</span>
                        </span> */}
                        </div>
                        {/* <BTNPrimary
                            className={`verify-email ${!userInfo.IsVerifiedEmail ? 'unverified' : 'success'}`}
                            title={userInfo.IsVerifiedEmail ? 'Verified' : 'Not verified'}
                            disabled={userInfo.IsVerifiedEmail}
                            onClick={async () => {
                                if (userInfo.IsVerifiedEmail) {
                                    return;
                                }
                                const { result } = await apiReSendVerifyEmail(window);

                                if (result) {
                                    dispatch(
                                        addToast({
                                            type: TOAST_TYPES.SUCCESS,
                                            title: 'Success!',
                                            description: 'Please check your email inbox.',
                                        }),
                                    );
                                } else {
                                    dispatch(
                                        addToast({
                                            type: TOAST_TYPES.ERROR,
                                            title: 'Oh snap!',
                                            description: 'Something wrong, please try again later',
                                        }),
                                    );
                                }
                            }}
                        /> */}
                    </div>
                </Row>
                <Row className="row-content">
                    <div className="kyc-status">
                        <span className="fs-large fw-bold">KYC Status</span>
                        <BTNPrimary
                            title={buttonText}
                            id="blockpass-kyc-connect"
                            className={className}
                            onClick={() => {}}
                            disabled={isDisabled}
                        />
                        <span className="fs-regular fw-regular kyc-text">
                            Verify your identity now to use the service. <br />
                            KYC is a verification process that allows an institution to confirm and thereby verify the
                            authenticity of their customers.
                        </span>
                        <span className="fs-regular fw-regular kyc-text-3">
                            Please make sure that your registered email on Blockpass and the email you signed up for
                            this account is the same. Otherwise, you will not pass the KYC.
                        </span>
                        <div className="kyc-text-2">
                            <a href="mailto:support@infinitelaunch.io">
                                <MailOutlined />
                                <span style={{ marginLeft: '10px' }}>Need help? Email us.</span>
                            </a>
                        </div>
                    </div>
                </Row>
            </Col>
        </Row>
    );
});

export default Account;
