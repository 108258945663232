import React from 'react';
import { Contract } from '@ethersproject/contracts';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import Web3 from 'web3';
import { getContract, getContractSkeletonABI, getContractSkeletonAddress } from 'src/functions/getContract';
import contractMarket from 'src/contracts/market.json';
import { isMainnet, ENVS } from 'src/configs/Configs.env';
import contractPrivateRoundLaunchPad from '../contracts/PrivateRoundLaunchpad.json';
import contractPublicSale from '../contracts/PublicSaleIDO.json';
import contractBusd from '../contracts/busd.json';
import contractFarm from '../contracts/farmming.json';
import contractERC20 from '../contracts/erc20.json';
import contractIDOPoolABI from '../contracts/IdoPool.json';
import contractIDOPoolNftABI from '../contracts/idoPoolNft.json';
import contractIDODiamondPoolABI from '../contracts/idoDiamondPool.json';

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
    const { library, account } = useActiveWeb3React();

    return React.useMemo(() => {
        if (!address || !ABI || !library) return null;
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
        } catch (error) {
            console.error('Failed to get contract', error);
            return null;
        }
    }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useSkeletonContract(withSignerIfPossible?: boolean): Contract | null {
    const contractABI: any = getContractSkeletonABI();
    const contractAddr = getContractSkeletonAddress();
    return useContract(contractAddr, contractABI, withSignerIfPossible);
}

export function usePoolContract(contractAddr: string, withSignerIfPossible?: boolean): Contract | null {
    const contractABI: any = contractPrivateRoundLaunchPad.abi;
    return useContract(contractAddr, contractABI, withSignerIfPossible);
}

export function usePublicPoolContract(contractAddr: string, withSignerIfPossible?: boolean): Contract | null {
    const contractABI: any = contractPublicSale.abi;
    return useContract(contractAddr, contractABI, withSignerIfPossible);
}

export function usePoolIDOContract(contractAddr: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddr, contractIDOPoolABI, withSignerIfPossible);
}
export function usePoolIDONftContract(contractAddr: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddr, contractIDOPoolNftABI, withSignerIfPossible);
}
export function usePoolIDODiamondContract(contractAddr: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddr, contractIDODiamondPoolABI, withSignerIfPossible);
}
export function usePoolIDOContractNotConnectWallet(
    contractAddr: string,
    withSignerIfPossible?: boolean,
): Contract | null {
    const contractABI: any = contractIDOPoolABI;
    const provideUrl = isMainnet
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);
    return contract;
}
export function usePoolIDODiamondContractNotConnectWallet(
    contractAddr: string,
    withSignerIfPossible?: boolean,
): Contract | null {
    const contractABI: any = contractIDODiamondPoolABI;
    const provideUrl = isMainnet
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);
    return contract;
}

export function useBusdContract(withSignerIfPossible?: boolean): Contract | null {
    const contractABI = contractBusd.abi;
    const contractAddr = isMainnet ? contractBusd.address.mainnet : contractBusd.address.testnet;
    return useContract(contractAddr, contractABI, withSignerIfPossible);
}

export function useILAContract(withSignerIfPossible: boolean, depositAddress: string): Contract | null {
    return useContract(depositAddress, contractERC20, withSignerIfPossible);
}

export function useFarmContract(contractAddr: string, withSignerIfPossible?: boolean): Contract | null {
    const contractABI = contractFarm;
    // const contractAddr = isMainnet ? contractILA.address.mainnet : contractILA.address.testnet;
    return useContract(contractAddr, contractABI, withSignerIfPossible);
}

export function useFarmContractNotConnectWallet(contractAddr: string) {
    const contractABI: any = contractFarm;
    const provideUrl = isMainnet
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);
    return contract;
}
