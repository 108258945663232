import { message } from 'antd';
import { useSelector } from 'react-redux';
import { apiGetPoolApprovedDetails, apiUpdateUserWhiteList } from 'src/modules/WhiteList/redux/WhiteList.service';
import { loadingSelector } from 'src/modules/Auth/Auth.selector';
import { IRootState } from 'src/redux/interface';
import { IWhiteListState } from 'src/modules/WhiteList/WhiteList.type';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import { actionSetLoading } from 'src/modules/Auth/Auth.actions';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';

export const ACTION_TYPES = {
    GET_POOL_APPROVED_DETAIL: 'GET_POOL_APPROVED_DETAIL',
    UPDATE_TODO_LIST: 'UPDATE_TODO_LIST',
    LOGOUT: 'LOGOUT',
};

export const actionSetToDoList = (payload: IWhiteListState) => ({
    type: ACTION_TYPES.UPDATE_TODO_LIST,
    payload,
});
export const actionLogout = () => ({
    type: ACTION_TYPES.LOGOUT,
});

export const actionGetPoolApprovedDetails =
    (projectName: any, token: string) => async (dispatch: any, getState: () => IRootState) => {
        try {
            const res = await apiGetPoolApprovedDetails(projectName, token);
            dispatch(actionSetToDoList({ WhiteListName: res.result?.PoolApproved?.WhiteListName }));
            dispatch({ type: ACTION_TYPES.GET_POOL_APPROVED_DETAIL, payload: res.result });
        } catch (error: any) {
            message.error(error.message);
        }
    };

export const actionJoinTele =
    (poolApprovedData: any, teleID: string, token: string) => async (dispatch: any, getState: () => IRootState) => {
        let authError = '';
        dispatch(actionSetLoading({ loading: true }));
        try {
            const data = await apiUpdateUserWhiteList(
                { PoolApprovedID: poolApprovedData?.ID, TelegramID: teleID },
                token,
            );
            if (data.error == null) {
                dispatch(actionGetPoolApprovedDetails(poolApprovedData?.WhiteListName, token));
                dispatch(
                    actionToggleModal({
                        visible: false,
                        data: null,
                    }),
                );
                dispatch(
                    addToast({
                        type: TOAST_TYPES.SUCCESS,
                        title: 'Awesome!',
                        description: 'You are now one of our Telegram members.',
                    }),
                );
            }
            dispatch(actionSetLoading({ loading: false }));
        } catch (e) {
            dispatch(actionSetLoading({ loading: false }));
            throw e;
        }
    };

export const actionJoinTwitter =
    (poolApprovedData: any, twitterUsername: string, token: string) =>
    async (dispatch: any, getState: () => IRootState) => {
        let authError = '';
        dispatch(actionSetLoading({ loading: true }));
        try {
            const data = await apiUpdateUserWhiteList(
                { PoolApprovedID: poolApprovedData?.ID, TwitterUsername: twitterUsername },
                token,
            );
            if (data.error == null) {
                dispatch(actionGetPoolApprovedDetails(poolApprovedData?.WhiteListName, token));
                dispatch(
                    actionToggleModal({
                        visible: false,
                        data: null,
                    }),
                );
                dispatch(
                    addToast({
                        type: TOAST_TYPES.SUCCESS,
                        title: 'Awesome!',
                        description: 'You are now one of our Twitter members.',
                    }),
                );
            } else {
                dispatch(actionSetToDoList({ error: data.error }));
            }
            dispatch(actionSetLoading({ loading: false }));
        } catch (e) {
            dispatch(actionSetLoading({ loading: false }));
            throw e;
        }
    };

export const actionJoinWalletAddress =
    (poolApprovedData: any, walletAddress: string, token: string) =>
    async (dispatch: any, getState: () => IRootState) => {
        dispatch(actionSetLoading({ loading: true }));
        let authError = '';
        try {
            const data = await apiUpdateUserWhiteList(
                { PoolApprovedID: poolApprovedData?.ID, WalletAddress: walletAddress },
                token,
            );
            if (data.error == null) {
                dispatch(actionGetPoolApprovedDetails(poolApprovedData?.WhiteListName, token));
                dispatch(
                    actionToggleModal({
                        visible: false,
                        data: null,
                    }),
                );
                dispatch(
                    addToast({
                        type: TOAST_TYPES.SUCCESS,
                        title: 'Awesome!',
                        description: 'Wallet address added successfully.',
                    }),
                );
            }
            dispatch(actionSetLoading({ loading: false }));
        } catch (e) {
            dispatch(actionSetLoading({ loading: false }));
            throw e;
        }
    };

export const actionSendContribute =
    (poolApprovedData: any, contributeType: any, contributeDescription: string, token: string) =>
    async (dispatch: any, getState: () => IRootState) => {
        dispatch(actionSetLoading({ loading: true }));
        let authError = '';
        try {
            const data = await apiUpdateUserWhiteList(
                {
                    PoolApprovedID: poolApprovedData?.ID,
                    ContributeType: contributeType,
                    ContributeDescription: contributeDescription,
                },
                token,
            );
            if (data.error == null) {
                dispatch(actionGetPoolApprovedDetails(poolApprovedData?.WhiteListName, token));
                dispatch(
                    actionToggleModal({
                        visible: false,
                        data: null,
                    }),
                );
                dispatch(
                    addToast({
                        type: TOAST_TYPES.SUCCESS,
                        title: 'Noted with thanks!',
                        description: 'If you are a good fit for what we are looking for, you sure will be whitelisted.',
                    }),
                );
            }
            dispatch(actionSetLoading({ loading: false }));
        } catch (e) {
            dispatch(actionSetLoading({ loading: false }));
            throw e;
        }
    };
