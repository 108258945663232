import styled, { ITheme } from 'styled-components';
import { Row } from 'antd';

export const Wrapper = styled(Row)`
    width: 100%;
    background: #3b3b3b;
    color: white;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid #4f4f4f;
    .top-header {
        margin: 0 auto;
        padding: 0 30px;
        width: 100%;
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-top-header {
            display: flex;
            align-items: center;
            .custom-ila-price {
                display: flex;
                align-items: center;
                .ila-price {
                    margin-left: 5px;
                    font-style: normal;
                    line-height: 40px;
                    span {
                        margin-left: 5px;
                        font-weight: 700;
                    }
                }
                .icon-ila {
                    svg {
                        width: 20px;
                    }
                }
            }
            .custom-contract-address {
                display: flex;
                align-items: center;
                margin-left: 20px;
                .title-contract {
                    color: #bfbfbf;
                }
                .contract-address {
                    display: flex;
                    .text-contract {
                        margin-left: 10px;
                        cursor: pointer;
                    }
                    .copy-contract {
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        .right-top-header {
            display: flex;
            align-items: center;
            .bulkhead {
                margin-left: 15px;
                font-weight: 700;
                color: #777e90;
            }
            .buy-ila-on {
                display: flex;
                align-items: center;
                margin-left: 15px;
                .title-buy-ila {
                    margin-right: 15px;
                    color: #bfbfbf;
                }
                .trading {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    .text-trading {
                        margin-left: 5px;
                        font-weight: 700;
                        color: hsla(0, 0%, 100%, 0.65);
                        &:hover {
                            color: ${({ theme }: { theme: ITheme }) => theme.text8};
                        }
                    }
                    .icon-trading {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        .btn-ila-connect {
            margin-left: 30px;
            background: linear-gradient(270deg, #fd7942 0%, #9546c2 48.61%, #15b2f4 100%);
            padding: 3px 20px;
            font-weight: 700;
            border-radius: 25px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        max-width: 100%;
        .hidden-on-mobile {
            display: none !important;
        }

        .top-header {
            margin: 5px auto;
        }
        .custom-ila-price {
            .ila-price {
                line-height: 30px !important;
            }
        }
    `}
`;
