import React, { memo, useEffect, useState } from 'react';
import styled, { ITheme } from 'styled-components';
import { Transaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { ReactComponent as WalletLogoSVG } from 'src/assets/svg/icons/wallet.svg';
import { ellipsisCenter } from 'src/utils/ellipsis';
import withWalletModal from 'src/components/WalletModal/WalletModal.enhence';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import useActiveBalance from 'src/hooks/useActiveBalance';
import { formatEther } from '@ethersproject/units';
import format from 'src/utils/format';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

const ButtonConnect = styled.button`
    width: 180px !important;
    height: 40px !important;
    margin-right: 12px;
    background: transparent !important;
    border: 2px solid ${({ theme }: { theme: ITheme }) => theme.dark3};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 10px;
    .logo {
        margin-left: 10px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-right: 0;
        justify-content: center;
        width: 100% !important;
        margin-bottom: 25px;
        padding-right: 0px;
        .logo {
           padding-right: 10px;
        }
    `}
`;

const BTNWallet = styled.button`
    width: fit-content !important;
    height: 40px !important;
    margin-right: 12px;
    background: transparent !important;
    border: 2px solid ${({ theme }: { theme: ITheme }) => theme.dark3};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 2px;
    padding-left: 12px;
    .logo {
        margin-left: 16px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    .addressText {
        background-color: ${({ theme }: { theme: ITheme }) => theme.dark7};
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
        line-height: 32px;
        border-radius: 16px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
    }

    .balanceText {
        color: ${({ theme }: { theme: ITheme }) => theme.text3};
        width: max-content;
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-right: 0;
        width: 100% !important;
        margin-bottom: 25px;
    `}
`;

interface IBTNProps {
    address?: string;
    openConnectWalletModal: () => void;
    openConnectDetail: () => void;
    isMobile?: boolean;
}

const BTNWalletInfo = React.memo(({ address, openConnectDetail }: { address: any; openConnectDetail: () => void }) => {
    const { balance, symbol: symbol2 } = useActiveBalance();

    const [balanceSolana, setBalanceSolana] = useState<any>('');
    const [symbol, setSymbol] = useState<any>(symbol2);
    const { connection } = useConnection();
    const { publicKey } = useWallet();
    useEffect(() => {
        if (publicKey && address === publicKey.toString()) {
            connection
                .getBalance(publicKey)
                .then((result) => {
                    setBalanceSolana(result);
                    setSymbol('SOL');
                })
                .catch(() => {
                    setBalanceSolana('');
                });
        } else {
            setBalanceSolana('');
        }
    }, [publicKey]);

    const showBalance = () => {
        if (balanceSolana) {
            return `${format.toFixed({ number: balanceSolana / LAMPORTS_PER_SOL, decimals: 2 })} ${symbol}`;
        }
        if (balance) {
            return `${format.toFixed({ number: formatEther(balance), decimals: 2 })} ${symbol2}`;
        }
        return `0 ${symbol}`;
    };
    return (
        <BTNWallet className="btn-connect-wallet btn-primary" onClick={openConnectDetail}>
            <p className="fw-bold balanceText">{showBalance()}</p>
            <p className="fw-bold addressText">{ellipsisCenter({ str: address || '', limit: 6 })}</p>
        </BTNWallet>
    );
});

const BTNConnectWallet = (props: IBTNProps & React.ButtonHTMLAttributes<HTMLButtonElement> & any) => {
    const { openConnectWalletModal, isMobile, openConnectDetail, ...rest } = props;
    const { account } = useActiveWeb3React();
    const { publicKey } = useWallet();

    let address = account;

    useEffect(() => {
        if (publicKey) {
            address = publicKey.toString();
        }
    }, [publicKey]);

    return React.useMemo(() => {
        if (address) return <BTNWalletInfo address={address} openConnectDetail={openConnectDetail} />;
        return (
            <ButtonConnect className="fw-bold btn-primary" onClick={() => openConnectWalletModal()} {...rest}>
                <WalletLogoSVG className={isMobile ? '' : 'logo'} />
                {!isMobile && <p>Connect Wallet</p>}
            </ButtonConnect>
        );
    }, [account, openConnectWalletModal, address]);
};

export default withWalletModal(memo(BTNConnectWallet));
