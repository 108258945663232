import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
    .title {
        margin-top: 14px;
        font-size: 14px;
        font-weight: 500;
        color: #777e91;
        text-align: center;
    }

    .sign-btn {
        margin-top: 24px;
        text-align: center;
        .btn-sign-wallet {
            border: none;
            background: linear-gradient(269.98deg, #fd7942 0.03%, #9546c2 48.61%, #15b2f4 99.97%);
            border-radius: 20px;
            color: white;
            font-weight: 700;
            font-size: 14px;
            height: 36px;
        }
    }

    .collapse-modal-sign-wallet {
        margin-top: 30px;
        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
            color: white;
        }
        .ant-collapse {
            background: rgb(39 52 66/1);
            border: none;
        }
        .ant-collapse-content {
            background: rgb(58 58 58 / 85%);
            color: #fff;
            border: 0;
        }
        .ant-collapse > .ant-collapse-item {
            border-bottom: 0;
        }
    }
`;
