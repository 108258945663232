import styled, { ITheme } from 'styled-components';
import { Row } from 'antd';

export const FooterFrame = styled.div`
    max-width: 1440px;
    padding: 0 160px;
    margin: 0 auto;
    margin-top: 200px;

    .line-break-top {
        height: 1px;
        width: 100%;
        background: #353945;
    }

    .top-footer {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .center-content {
            max-width: 540px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #b1b5c4;
        }

        .right-socials {
            display: flex;
            a {
                margin-left: 32px;
            }
        }
    }
    .middle-footer {
        margin-top: 60px;
        .row-middle {
            display: flex;
            justify-content: space-between;

            .general,
            .help,
            .communities,
            .token,
            .audits {
                .header-item-column {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;
                    color: #777e91;
                }
                .content-item-column {
                    margin-top: 16px;
                    a {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: #ffffff;
                        display: flex;
                        align-items: center;

                        .ant-image {
                            margin-right: 5px;
                        }
                    }
                }
            }

            .audits {
                .content-item-column {
                    .ant-image {
                        width: 80px;
                    }
                }
            }
        }
    }
    .bottom-footer {
        margin-top: 60px;
        padding-bottom: 38px;
        .description,
        .copy-right {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #b1b5c4;
        }

        .copy-right {
            margin-top: 12px;
        }
    }

    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        max-width: 100%;
        padding: 0 24px;
        margin: 0 auto;
        margin-top: 120px;
        width: 100%;

        .line-break-top {
            height: 1px;
            width: 100%;
            background: #353945;
        }

        .top-footer {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .center-content {
                margin-top: 12px;
            }

            .right-socials {
                margin-top: 12px;
                a {
                    margin-left: 0;
                    margin-right: 24px;
                }
            }
        }
        .middle-footer {
            margin-top: 30px;
            .row-middle {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                // flex-flow: wrap-reverse;
                .general,
                .help,
                .communities,
                .token,
                .audits {
                    .header-item-column {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 30px;
                        color: #777e91;
                    }
                    .content-item-column {
                        margin-top: 16px;
                        a {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: #ffffff;
                            display: flex;
                            align-items: center;

                            .ant-image {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
        .bottom-footer {
            margin-top: 30px;
            padding-bottom: 40px;
            .description,
            .copy-right {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #b1b5c4;
            }

            .copy-right {
                margin-top: 12px;
            }
        }
    `}
`;
