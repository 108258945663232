import React, { memo } from 'react';
import { Dropdown, Menu, Row } from 'antd';
import styled from 'styled-components';
import useActiveNetwork from 'src/hooks/useActiveNetwork';
import useActiveWallet from 'src/hooks/useActiveWallet';
import withConnect from 'src/hoc/withConnect';
import { ArrowIcon } from 'src/components/Icons';
import { WalletMultiButton } from '@solana/wallet-adapter-ant-design';
import { ReactComponent as SolanaSvg } from 'src/assets/svg/network-logos/solana.svg';
import { useDispatch } from 'react-redux';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { SUPPORTED_NETWORKS } from 'src/configs/Connect/Configs.networks';
import { useWallet } from '@solana/wallet-adapter-react';
import withWalletModal from 'src/components/WalletModal/WalletModal.enhence';
import { ACTION_TYPES } from '../redux/Wallet.action';

const Button = styled.button`
    :disabled {
        opacity: 0.5;
        :hover {
            cursor: pointer;
            transform: scale(1);
        }
    }
    .logo {
        margin-left: 16px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    .icon-arrow {
        margin-left: 10px;
    }
`;

interface IBTNProps {
    className?: string;
    onClick?: () => void;
    item?: any;
}

const BTNNetwork = (props: IBTNProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const { className, onClick, item, ...rest } = props;

    return (
        <Button className={`btn-primary ${className}`} onClick={onClick} {...rest}>
            {item.chainName}
            <ArrowIcon className="icon-arrow" />
        </Button>
    );
};

const MenuContainer = styled(Menu)`
    border-radius: 16px;
    .logo {
        margin-right: 16px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    .item-solana {
        margin-top: -5px;
        .custom-solana-dropdown {
            background: none;
            border: 0;
            height: 30px;
            align-items: center;
            font-size: 12px;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 5px 12px;
            .wallet-adapter-icon {
                margin-right: 15px;
                margin-left: -12px;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                white-space: nowrap;
                cursor: pointer;
                color: rgba(255, 255, 255, 0.65);
                &:hover {
                    color: white;
                }
            }
            svg {
                margin-right: 15px;
                margin-left: -12px;
                width: 24px;
                height: 24px;
            }
        }
    }
`;
const supportNetworks = [
    SUPPORTED_NETWORKS[ChainId.MAINNET],
    SUPPORTED_NETWORKS[ChainId.BSC],
    SUPPORTED_NETWORKS[ChainId.MATIC],
    SUPPORTED_NETWORKS[ChainId.FANTOM],
    SUPPORTED_NETWORKS[ChainId.CRO],
    SUPPORTED_NETWORKS[ChainId.AVALANCHE],
];
const DropdownNetwork = ({ openConnectWalletModal }: any) => {
    const { deactivate } = useActiveWeb3React();
    const activeNetwork = useActiveNetwork();
    const activeWallet = useActiveWallet();
    const { wallet, publicKey } = useWallet();
    const dispatch = useDispatch();
    const onChooseNetwork = (item: any) => {
        if (!publicKey) {
            dispatch({
                type: ACTION_TYPES.SET_CHAIN_ID,
                payload: item.chainNumb,
            });
        } else {
            openConnectWalletModal(item.chainNumb);
        }
    };
    const menus = () => (
        <MenuContainer theme="dark" mode="horizontal">
            {supportNetworks.map((item: any) => {
                return (
                    <Menu.Item
                        key={item.chainId}
                        // disabled={item.chainId === activeWallet.chainId}
                        onClick={() => onChooseNetwork(item)}
                    >
                        <Row>
                            <img
                                className="logo"
                                alt=""
                                src={require(`src/assets/svg/network-logos${item.path}`).default}
                            />
                            <p>{item.chainName}</p>
                        </Row>
                    </Menu.Item>
                );
            })}
            <Menu.Item className="item-solana" key="solana">
                <WalletMultiButton
                    className="custom-solana-dropdown"
                    onClick={() => {
                        deactivate();
                        localStorage.removeItem('connector');
                        dispatch({
                            type: ACTION_TYPES.SET_CHAIN_ID,
                            payload: null,
                        });
                    }}
                >
                    {' '}
                    {!publicKey && <SolanaSvg />}
                    <p>Solana</p>
                </WalletMultiButton>
            </Menu.Item>
        </MenuContainer>
    );
    if (wallet?.name) {
        return (
            <Dropdown overlay={menus} placement="bottomRight">
                <BTNNetwork className="btn-network" item={{ chainName: 'Solana' }} />
            </Dropdown>
        );
    }

    if (!activeNetwork || !activeWallet || !activeWallet.supportNetworks) return null;

    return (
        <Dropdown overlay={menus} placement="bottomRight">
            <BTNNetwork className="btn-network" item={activeNetwork} />
        </Dropdown>
    );
};

export default withWalletModal(withConnect(memo(DropdownNetwork)));
