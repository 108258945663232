import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { BTNPrimary } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { ellipsisCenter } from 'src/utils/ellipsis';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { userInfoSelector } from 'src/modules/Auth/Auth.selector';
import ModelAddOtherWallet from '../modal/ModelAddOtherWallet';
import UnlockTierForm from '../modal/ModalUnlockTier';
import LockTierModal from './LockTierModal';
import { unlock } from '../redux/Account.service';
import { actionUpdateOtherWallet, ACTION_TYPES, getILABalanceAction } from '../redux/Account.action';

const dataWallet = [
    {
        name: 'Solana',
        isConnected: false,
    },
    {
        name: 'Avalance',
        isConnected: true,
    },
];

const OtherNetwork = React.memo(() => {
    const dispatch = useDispatch();
    const userInfoEs = useSelector(userInfoSelector);
    const { userInfo }: any = useSelector((state: any) => state.account);

    const token = userInfoEs.auth.accessToken;

    console.log('userInfo', userInfo);

    const handleDisconnect = (address: string) => {
        const arrayWallet = userInfo?.Wallets.filter((element: any) => element.address !== address);
        dispatch(
            actionUpdateOtherWallet(
                {
                    Wallets: arrayWallet,
                },
                token,
            ),
        );
    };

    const renderConectedWallet = ({ address, network }: any) => {
        return (
            <Row className="row-content" style={{ marginTop: 4 }}>
                <Col lg={24} xs={24} className="other-network">
                    <div className="fs-large fw-bold header-other-network">{network}</div>
                    <div className="fs-medium wallet-address">
                        <span className="other-network-label"> Address </span>
                        <span className="other-network-address">
                            {ellipsisCenter({ str: address || '', limit: 10 })}
                        </span>
                    </div>
                    <div className="btn-other-network">
                        <Button
                            type="primary"
                            onClick={() => {
                                handleDisconnect(address);
                            }}
                        >
                            Disconnected
                        </Button>
                    </div>
                </Col>
            </Row>
        );
    };

    const renderDisconectedWallet = (name: string) => {
        return (
            <Row className="row-content" style={{ marginTop: 4 }}>
                <Col lg={24} xs={24} className="avalanche">
                    <div className="header-avalanche fw-bold">{name}</div>
                    <div className="address">
                        <div className="name-address">Address</div>
                        <div className="na-address">N/A</div>
                    </div>
                    <div className="custom-btn-connect">
                        <BTNPrimary
                            className="btn-connect-wallet"
                            title="Submit Address"
                            onClick={() => {
                                dispatch(
                                    actionToggleModal({
                                        visible: true,
                                        title: 'Submit Address',
                                        data: <ModelAddOtherWallet name={name} />,
                                    }),
                                );
                            }}
                        />
                    </div>
                </Col>
            </Row>
        );
    };

    const renderConnectWallet = () => {
        return ['Solana', 'Avalanche'].map((e) => {
            const value = userInfo.Wallets?.find((a: any) => a.network?.toLowerCase() === e.toLowerCase());
            if (value) {
                return <>{renderConectedWallet(value)}</>;
            }
            return <>{renderDisconectedWallet(e)}</>;
        });
    };

    return (
        <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }}>
                {renderConnectWallet()}
            </Col>
        </Row>
    );
});

export default OtherNetwork;
