import React, { memo, useEffect, useState } from 'react';
import { COMPONENT_IDS } from 'src/app/App.constant';
import { MenuOutlined } from '@ant-design/icons';
import withLogin from 'src/hoc/login.enhance';
import withConnect from 'src/hoc/withConnect';
import { ReactComponent as LogoSVG } from 'src/assets/svg/logo-infinite-launch.svg';
import { ReactComponent as DownServiceSvg } from 'src/assets/svg/header/service-down-ic.svg';
import { ReactComponent as UpServiceSvg } from 'src/assets/svg/header/service-up-ic.svg';
import { ReactComponent as SupportIcSvg } from 'src/assets/svg/header/support-ic.svg';
import { ReactComponent as ArrowNextSvg } from 'src/assets/svg/header/arrow-next-ic.svg';
import { ReactComponent as FundingIcSvg } from 'src/assets/svg/header/funding-ic.svg';
import { ReactComponent as StakeIcSvg } from 'src/assets/svg/header/stake-ic.svg';
import { ReactComponent as NftIcSvg } from 'src/assets/svg/header/nft-ic.svg';
import { ReactComponent as SaveIcSvg } from 'src/assets/svg/header/saving-ic.svg';
import { ReactComponent as ContributeIcSvg } from 'src/assets/svg/header/contribute-ic.svg';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import { NavLink, useHistory } from 'react-router-dom';

import GuaranteedAllocationImg from 'src/assets/svg/header/guaranteedAllocation .png';
import { Button, Col, Drawer, Image, Row } from 'antd';
import { useSelector } from 'react-redux';
import { isLoginSelector } from 'src/modules/Auth/Auth.selector';
import { compose } from 'recompose';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { HeaderFrame } from './HeaderV2.styled';
import TopHeader from './components/TopHeader';
import DropdownNetwork from './components/DropdownNetwork';
import MenuMobileV2 from './components/MenuMobileV2';
import ButtonConnectWallet from './components/ButtonConnectWallet';
import DropdownAccount from './components/DropdownAccount';
import ButtonPrimary from '../Button/Button.primary';
import { menuItem } from './HardData';

interface IHeaderProps {
    openLoginModal: () => void;
    changeChainId: (chainId: number) => void;
}
function HeaderV2({ openLoginModal, changeChainId }: IHeaderProps & any) {
    const [activeService, setActiveService] = useState(false);
    const [visible, setVisible] = useState(false);
    const [scroll, setScroll] = useState(window.pageYOffset);
    const isLogin = useSelector(isLoginSelector);
    const { account } = useActiveWeb3React();
    const { chainId } = useSelector((state: any) => state.wallet);

    let { pageYOffset } = window;
    window.addEventListener('scroll', (event) => {
        pageYOffset = window.pageYOffset;
        setScroll(pageYOffset);
    });
    const openMenu = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    useEffect(() => {
        if (account && chainId) {
            changeChainId(chainId);
        }
    }, [chainId, account]);
    return (
        <>
            <TopHeader />
            <HeaderFrame
                id={COMPONENT_IDS.HEADER}
                style={{
                    top: scroll > 20 ? 0 : 'unset',
                    transition: '1s',
                }}
            >
                <div className="menu-desktop">
                    <div className="left-header">
                        <div className="logo">
                            <NavLink to="/">
                                <LogoSVG />
                            </NavLink>
                        </div>
                        <div
                            className="box-dropdown-service"
                            onMouseEnter={() => {
                                setActiveService(true);
                            }}
                            onMouseLeave={() => {
                                setActiveService(false);
                            }}
                        >
                            <div
                                className={
                                    activeService ? 'service fw-bold fs-small active' : 'service fw-bold fs-small'
                                }
                            >
                                Services
                                {activeService ? <UpServiceSvg /> : <DownServiceSvg />}
                            </div>
                            <div className={activeService ? 'box-dropdown active' : 'box-dropdown'}>
                                <Row className="row-dropdown">
                                    <Col md={9} className="col-dropdown">
                                        <div className="item-dropdown">
                                            <div className="icon">
                                                <FundingIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/join-ido">
                                                    <div className="text fw-bold fs-small">Fundraising</div>
                                                    <div className="hint fw-regular fs-suppersmall">
                                                        Participate in IDO, IGO, INO sales
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <StakeIcSvg />
                                            </div>
                                            <div
                                                className="item-text"
                                                onClick={() => {
                                                    if (!isLogin) {
                                                        openLoginModal();
                                                    }
                                                }}
                                            >
                                                <NavLink to="/accounts?tab=wallet">
                                                    <div className="text fw-bold fs-small">Stake & Earn</div>
                                                    <div className="hint fw-regular fs-suppersmall">
                                                        Buy ILA and lock to earn high APR
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <NftIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/marketplaces?status=all&sort=PriceAsc">
                                                    <div className="text fw-bold fs-small">NFT Marketplace</div>
                                                    <div className="hint fw-regular fs-suppersmall">
                                                        Trading NFTs, pre-vested token and more
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <NftIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/nft-analytics">
                                                    <div className="text fw-bold fs-small">NFT Sniper Tool</div>
                                                    <div className="hint fw-regular fs-suppersmall">
                                                        Never miss the latest NFT gems ever again
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={9} className="col-dropdown border-left-item">
                                        <div className="item-dropdown">
                                            <div className="icon">
                                                <ContributeIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/army">
                                                    <div className="text fw-bold fs-small">
                                                        Become Core Contributors
                                                    </div>
                                                    <div className="hint fw-regular fs-suppersmall">
                                                        Join ILA Army here
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <SaveIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/stake-farming?type=farms">
                                                    <div className="text fw-bold fs-small">LP Farming</div>
                                                    <div className="hint fw-regular fs-suppersmall">
                                                        Buy ILA and add liquidity to earn high APR
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="box-upgrade-tier">
                                                <NavLink
                                                    to="/accounts?tab=wallet"
                                                    onClick={() => {
                                                        if (!isLogin) {
                                                            openLoginModal();
                                                        }
                                                    }}
                                                >
                                                    <div className="left fs-suppersmall">Upgrade your tier rank</div>
                                                </NavLink>
                                                <div
                                                    className="right fs-suppersmall"
                                                    onClick={() => {
                                                        window.open('https://t.me/InfiniteLaunch');
                                                    }}
                                                >
                                                    <SupportIcSvg />
                                                    Support
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} className="col-dropdown">
                                        <div className="guaranteed-allocation">
                                            <Image preview={false} src={GuaranteedAllocationImg} />
                                            <div className="text-guaranteed fw-bold fs-small">
                                                Get Guaranteed Allocation
                                            </div>
                                            <div className="hint-guaranteed fw-regular fs-small">
                                                To access any IDO pool, you need ILA Tier or own an NFT.
                                            </div>
                                            <NavLink to="/how-to-get-guaranteed-allocation">
                                                <div className="how-it-work fs-small fw-regular">
                                                    See how it work <ArrowNextSvg />
                                                </div>
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div
                            className="buy-ila fw-bold fs-small"
                            onClick={() => {
                                window.open(
                                    'https://pancakeswap.finance/swap?outputCurrency=0x4fbedc7b946e489208ded562e8e5f2bc83b7de42',
                                    '_blank',
                                );
                            }}
                        >
                            Buy ILA
                        </div>
                        <div
                            className="blog-ila fw-bold fs-small"
                            onClick={() => {
                                window.open('http://blog.infinitelaunch.io/');
                            }}
                        >
                            Blog
                        </div>
                    </div>
                    <div className="right-header">
                        <div className="network">
                            <DropdownNetwork />
                            <ButtonConnectWallet />
                            {isLogin ? (
                                <DropdownAccount />
                            ) : (
                                <ButtonPrimary
                                    title={isLogin ? 'Account' : 'Login'}
                                    className="btn-login"
                                    onClick={openLoginModal}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="menu-mobile">
                    <div className="connect-mobile">
                        <DropdownNetwork />
                        <Button
                            className="btn-menu-mobile"
                            type="primary"
                            shape="round"
                            size="large"
                            onClick={openMenu}
                        >
                            <MenuOutlined />
                        </Button>
                    </div>
                    <Drawer
                        placement="right"
                        width="100%"
                        closable
                        visible={visible}
                        key="right"
                        onClose={onClose}
                        className="drawer-header-mobile"
                        drawerStyle={{ backgroundColor: '#141416' }}
                        closeIcon={<IcClose style={{ marginTop: 8 }} width={20} height={20} />}
                    >
                        <MenuMobileV2 onClose={onClose} />
                    </Drawer>
                </div>
            </HeaderFrame>
        </>
    );
}
export default compose(withConnect, withLogin)(memo(HeaderV2));
