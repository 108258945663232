import React from 'react';
import { ReactComponent as LogoILASVG } from 'src/assets/svg/logo-infinite-launch-mobile.svg';
import { ReactComponent as CopyIcon } from 'src/assets/svg/ic-copy.svg';
import { ReactComponent as CopiedIcon } from 'src/assets/svg/ic-copied.svg';
import { ReactComponent as PancakeIcon } from 'src/assets/svg/ic-pancake.svg';
import { ReactComponent as KucoinIcon } from 'src/assets/svg/ic-kucoin.svg';
import { ReactComponent as BonyIcon } from 'src/assets/svg/ic-bony.svg';
import useCopyClipboard from 'src/hooks/useCopyClipboard';
import { ellipsisCenter } from 'src/utils/ellipsis';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './TopHeader.styled';

export default function TopHeader() {
    const [isCopied, setCopied] = useCopyClipboard();
    const history = useHistory();
    const { ILAPrice } = useSelector((state: any) => state.stakeFarming);
    return (
        <Wrapper>
            <div className="top-header">
                <div className="left-top-header">
                    <div className="custom-ila-price">
                        <div className="icon-ila" onClick={() => history.push('/')}>
                            <LogoILASVG width={20} height={20} />
                        </div>
                        <div className="ila-price">
                            ILA: <span>${ILAPrice?.data?.price}</span>
                        </div>
                    </div>
                    <div className="custom-contract-address hidden-on-mobile">
                        <div className="title-contract">Contract Address:</div>
                        <div className="contract-address">
                            <div
                                className="text-contract"
                                onClick={() => {
                                    window.open(
                                        'https://bscscan.com/address/0x4fbedc7b946e489208ded562e8e5f2bc83b7de42',
                                        '_blank',
                                    );
                                }}
                            >
                                {ellipsisCenter({ str: '0x4fbedc7b946e489208ded562e8e5f2bc83b7de42', limit: 10 })}
                            </div>
                            <div
                                className="copy-contract"
                                onClick={() => setCopied('0x4fbedc7b946e489208ded562e8e5f2bc83b7de42')}
                            >
                                {isCopied && (
                                    <div className="items-center">
                                        <CopiedIcon />
                                    </div>
                                )}

                                {!isCopied && <CopyIcon />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-top-header">
                    <div className="buy-ila-on">
                        <div className="title-buy-ila hidden-on-mobile">Buy ILA: </div>
                        <div className="trading">
                            <div
                                className="icon-trading"
                                onClick={() => {
                                    window.open(
                                        'https://pancakeswap.finance/swap?outputCurrency=0x4fbedc7b946e489208ded562e8e5f2bc83b7de42',
                                        '_blank',
                                    );
                                }}
                            >
                                <PancakeIcon width={25} height={25} />
                            </div>
                            <div
                                className="text-trading hidden-on-mobile"
                                onClick={() => {
                                    window.open(
                                        'https://pancakeswap.finance/swap?outputCurrency=0x4fbedc7b946e489208ded562e8e5f2bc83b7de42',
                                        '_blank',
                                    );
                                }}
                            >
                                Pancake
                            </div>
                        </div>
                    </div>
                    <div className="buy-ila-on">
                        <div className="trading">
                            <div
                                className="icon-trading"
                                onClick={() => {
                                    window.open(
                                        'https://trade.kucoin.com/trade/ILA-USDT?spm=kcWeb.B5markets.tradeList.1',
                                        '_blank',
                                    );
                                }}
                            >
                                <KucoinIcon width={25} height={25} />
                            </div>
                            <div
                                className="text-trading hidden-on-mobile"
                                onClick={() => {
                                    window.open(
                                        'https://trade.kucoin.com/trade/ILA-USDT?spm=kcWeb.B5markets.tradeList.1',
                                        '_blank',
                                    );
                                }}
                            >
                                KuCoin
                            </div>
                        </div>
                    </div>
                    <div className="bulkhead">|</div>
                    <div className="buy-ila-on">
                        <div className="title-buy-ila hidden-on-mobile">Buy Bony NFT: </div>
                        <div className="trading">
                            <div
                                className="icon-trading"
                                onClick={() => {
                                    history.push('/collection/bony-bastards?status=buy-now&sort=PriceAsc');
                                }}
                            >
                                <BonyIcon width={25} height={25} />
                            </div>
                            <div
                                className="text-trading hidden-on-mobile"
                                onClick={() => {
                                    history.push('/collection/bony-bastards?status=buy-now&sort=PriceAsc');
                                }}
                            >
                                Bony NFT
                            </div>
                        </div>
                    </div>
                    <div
                        className="btn-ila-connect hidden-on-mobile"
                        onClick={() => history.push('/stake-farming?type=pools')}
                    >
                        Stake & Earn
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
