import styled from 'styled-components';

const GradientText = styled.p`
    background: -webkit-linear-gradient(-75deg, #15b2f4 0%, #9546c2 50%, #fd7942 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &::selection {
        -webkit-text-fill-color: white;
    }
`;

export default GradientText;
