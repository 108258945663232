import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { BTNPrimary } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { ellipsisCenter } from 'src/utils/ellipsis';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import WalletModal from 'src/components/WalletModal';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { apiGetSettings } from 'src/modules/Collections/redux/Collections.service';
import UnlockTierForm from '../modal/ModalUnlockTier';
import LockTierModal from './LockTierModal';
import { unlock, claim } from '../redux/Account.service';
import { ACTION_TYPES, getILABalanceAction, getAccountLockTierInfo } from '../redux/Account.action';
import { ReactComponent as IcBronze } from '../../../assets/svg/ic-bronze.svg';
import { ReactComponent as IcSilver } from '../../../assets/svg/ic-silver.svg';
import { ReactComponent as IcGold } from '../../../assets/svg/ic-gold.svg';
import { ReactComponent as IcPlatinum } from '../../../assets/svg/ic-platinum.svg';
import { ReactComponent as IcNone } from '../../../assets/svg/ic-tier-none.svg';
import ModalUnlockTierV2 from '../modal/ModalUnlockTierV2';

const tierList = [
    {
        badge: '',
        label: 'Start',
        value: 0,
        info: [
            'APR',
            'Allocation',
            'Allocation limit per individual',
            'Exclusive pools',
            'Exclusive tokens airdrop',
            'NFT/artwork airdrop',
            'Private whale group',
        ],
        processBar: '2%',
    },
    {
        badge: <IcBronze />,
        label: 'Bronze',
        value: 20000,
        info: ['10%', 'yes', 'max 20$', 'no', 'no', 'no', 'no'],
        processBar: '30%',
    },
    {
        badge: <IcSilver />,
        label: 'Silver',
        value: 60000,
        info: ['20%', 'yes', 'max 50$', 'no', 'no', 'no', 'no'],
        processBar: '50%',
    },
    {
        badge: <IcGold />,
        label: 'Gold',
        value: 200000,
        info: ['30%', 'yes', 'max 200$', 'yes', 'no', 'Basic NFT', 'no'],
        processBar: '75%',
    },
    {
        badge: <IcPlatinum />,
        label: 'Platinum',
        value: 1000000,
        info: ['40%', 'yes', 'max 1000$', 'yes', 'yes', 'Exclusive NFT', 'yes'],
        processBar: '100%',
    },
];

const tierListMobile = [
    {
        badge: <IcBronze />,
        label: 'Bronze',
        value: 20000,
        info: ['10%', 'yes', 'max 20$', 'no', 'no', 'no', 'no'],
        processBar: '30%',
        description: [
            'APR',
            'Allocation',
            'Allocation limit per individual',
            'Exclusive pools',
            'Exclusive tokens airdrop',
            'NFT/artwork airdrop',
            'Private whale group',
        ],
    },
    {
        badge: <IcSilver />,
        label: 'Silver',
        value: 60000,
        info: ['20%', 'yes', 'max 50$', 'no', 'no', 'no', 'no'],
        processBar: '50%',
        description: [
            'APR',
            'Allocation',
            'Allocation limit per individual',
            'Exclusive pools',
            'Exclusive tokens airdrop',
            'NFT/artwork airdrop',
            'Private whale group',
        ],
    },
    {
        badge: <IcGold />,
        label: 'Gold',
        value: 200000,
        info: ['30%', 'yes', 'max 200$', 'yes', 'no', 'Basic NFT', 'no'],
        processBar: '75%',
        description: [
            'APR',
            'Allocation',
            'Allocation limit per individual',
            'Exclusive pools',
            'Exclusive tokens airdrop',
            'NFT/artwork airdrop',
            'Private whale group',
        ],
    },
    {
        badge: <IcPlatinum />,
        label: 'Platinum',
        value: 1000000,
        info: ['40%', 'yes', 'max 1000$', 'yes', 'yes', 'Exclusive NFT', 'yes'],
        processBar: '100%',
        description: [
            'APR',
            'Allocation',
            'Allocation limit per individual',
            'Exclusive pools',
            'Exclusive tokens airdrop',
            'NFT/artwork airdrop',
            'Private whale group',
        ],
    },
];

const disclaimerList = [
    {
        label: 'Less than 7 days ago',
        value: '30%',
    },
    {
        label: 'Less than 14 days ago',
        value: '25%',
    },
    {
        label: 'Less than 21 days ago',
        value: '20%',
    },
    {
        label: 'Less than 30 days ago',
        value: '10%',
    },
    {
        label: 'Less than 60 days ago',
        value: '5%',
    },
    {
        label: 'After 60 days',
        value: '0%',
    },
];

const MyWallet = React.memo(() => {
    const dispatch = useDispatch();
    const { account, chainId } = useActiveWeb3React();
    const {
        ilaBalance,
        lockedIn,
        lastDeposit,
        currentPenalty,
        apr,
        tierAPR,
        amount,
        reward,
        lastStakeTime,
        lastUpdate,
    }: any = useSelector((state: any) => state.account);
    const [loadingHarvest, setLoadingHarvest] = useState<boolean>(false);
    const [contractAddressLockTier, setContractAddressLockTier] = useState<any>('');

    const getLockTierContract = async () => {
        const result: any = await apiGetSettings('locked_tier_contract_address');
        if (!result) {
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Waiting!',
                    description: 'Can not get contract lock ILA',
                }),
            );
        }
        setContractAddressLockTier(result.Data);
        return result.Data;
    };

    const gerData = async () => {
        const contract: any = await getLockTierContract();
        dispatch(getILABalanceAction(account));
        dispatch(getAccountLockTierInfo(account, contract));
    };
    const getLastDeposit = () => {
        if (!lastStakeTime) return 0;
        const currentDate = Date.now();
        let diffDays = parseInt(((currentDate - lastStakeTime * 1000) / (1000 * 60 * 60 * 24)).toString(), 10);
        return diffDays;
    };
    const getCurrentPenalty = () => {
        if (!lastStakeTime) return 0;
        const diffDays = getLastDeposit();
        if (diffDays < 7) {
            return 30;
        }
        if (diffDays < 14) {
            return 25;
        }
        if (diffDays < 21) {
            return 20;
        }
        if (diffDays < 30) {
            return 10;
        }
        if (diffDays < 60) {
            return 5;
        }
        return 0;
    };
    useEffect(() => {
        if (!account) {
            dispatch({ type: ACTION_TYPES.CLEAR_ILA_BALANCE });
            dispatch({ type: ACTION_TYPES.CLEAR_ILA_LOCK_TIER });
            return;
        }
        gerData();
    }, [account, chainId]);

    const openUnlockTierForm = (title: any, callBack: any) => {
        dispatch(
            actionToggleModal({
                visible: true,
                data: <UnlockTierForm title={title} callBack={callBack} />,
                customModal: true,
                width: 420,
            }),
        );
    };
    const openUnlockTierV2 = () => {
        getCurrentPenalty();
        if (amount === 0) {
            return;
        }
        dispatch(
            actionToggleModal({
                visible: true,
                data: <ModalUnlockTierV2 contractAddress={contractAddressLockTier} />,
                customModal: true,
                width: 420,
            }),
        );
    };

    const processPercent = () => {
        switch (tierAPR) {
            case 10:
                return tierList[1].processBar;
            case 20:
                return tierList[2].processBar;
            case 30:
                return tierList[3].processBar;
            case 40:
                return tierList[4].processBar;
            default:
                return tierList[0].processBar;
        }
    };
    const tierMobile = () => {
        switch (tierAPR) {
            case 10:
                return <IcBronze />;
            case 20:
                return <IcSilver />;
            case 30:
                return <IcGold />;
            case 40:
                return <IcPlatinum />;
            default:
                return <></>;
        }
    };

    const onUnlock = async () => {
        if (lockedIn === 0) {
            return;
        }

        openUnlockTierForm('Confirm Unlock Tier', async (handleClose: () => void) => {
            const result = await unlock();
            try {
                if (!result) {
                    throw new Error();
                }

                dispatch(
                    addToast({
                        type: TOAST_TYPES.WAITING,
                        title: 'Waiting!',
                        description: 'Your transaction processing.',
                    }),
                );

                await result.wait();

                dispatch(
                    addToast({
                        type: TOAST_TYPES.SUCCESS,
                        title: 'Well done!',
                        description: 'Your transaction successfully.',
                    }),
                );

                if (account) {
                    dispatch(getILABalanceAction(account));
                }
            } catch (error: any) {
                console.log('error: ', error);
                dispatch(
                    addToast({
                        type: TOAST_TYPES.ERROR,
                        title: 'Oh snap!',
                        description: 'Change a few things up and try submitting again.',
                    }),
                );
            }
        });
    };

    const handleHarvest = async () => {
        try {
            setLoadingHarvest(true);
            const tx = await claim(contractAddressLockTier);
            if (!tx) return;
            dispatch(
                addToast({
                    type: TOAST_TYPES.WAITING,
                    title: 'Waiting!',
                    description: 'Your transaction processing.',
                }),
            );
            await tx.wait();
            dispatch(
                addToast({
                    type: TOAST_TYPES.SUCCESS,
                    title: 'Well done!',
                    description: 'Your transaction successfully.',
                }),
            );
            gerData();
            setLoadingHarvest(false);
        } catch (error: any) {
            setLoadingHarvest(false);
            console.log('handleHarvest error: ', error);
            let message = error.data?.message === undefined ? error?.message : error.data?.message;
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Oh snap!',
                    description: message,
                }),
            );
        }
    };

    return (
        <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }}>
                <div className="lock-tier">
                    <div className="left-lock-tier">
                        <div className="text-lock">
                            <span className="fs-large fw-bold">Lock Tier V2</span>
                        </div>
                        <div className="address-lock">
                            {account ? (
                                <span className="wallet-address">
                                    Addr: {ellipsisCenter({ str: account, limit: 10 })}
                                </span>
                            ) : (
                                // <BTNConnectWallet className="btn-connect-wallet" />
                                <div className="custom-btn-connect">
                                    <BTNPrimary
                                        className="btn-connect-wallet"
                                        title="Connect Wallet"
                                        onClick={() => {
                                            dispatch(
                                                actionToggleModal({
                                                    visible: true,
                                                    title: 'Select a Wallet',
                                                    width: 678,
                                                    data: <WalletModal />,
                                                }),
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="right-lock-tier">
                        <div className="item-action-token">
                            <div className="hint-lock">ILA REWARD</div>
                            <div className="num-lock">{reward?.toLocaleString() ?? 0}</div>

                            <BTNPrimary
                                title="Claim"
                                loading={loadingHarvest}
                                className={`${reward === 0 ? 'disable' : ''}`}
                                onClick={handleHarvest}
                            />
                        </div>
                        <div className="item-action-token">
                            <div className="hint-lock">ILA in wallet</div>
                            <div className="num-lock">{ilaBalance.toLocaleString() ?? 0}</div>
                            <BTNPrimary
                                className=""
                                title="Lock Tier"
                                onClick={() => {
                                    dispatch(
                                        actionToggleModal({
                                            visible: true,
                                            data: (
                                                <LockTierModal
                                                    tierList={tierList}
                                                    contractAddress={contractAddressLockTier}
                                                />
                                            ),
                                        }),
                                    );
                                }}
                            />
                        </div>
                        <div className="item-action-token">
                            <div className="hint-lock">LOCKED-IN</div>
                            <div className="num-lock">{amount?.toLocaleString() ?? 0}</div>
                            <BTNPrimary
                                className={`${amount === 0 ? 'disable' : ''}`}
                                title="Unlock"
                                onClick={() => {
                                    openUnlockTierV2();
                                }}
                            />
                        </div>
                    </div>
                </div>
                {lockedIn ? (
                    <div className="lock-tier">
                        <div className="left-lock-tier">
                            <div className="text-lock">
                                <span className="fs-large fw-bold">Lock Tier V1</span>
                            </div>
                            <div className="address-lock">
                                {account ? (
                                    <span className="wallet-address">
                                        Addr: {ellipsisCenter({ str: account, limit: 10 })}
                                    </span>
                                ) : (
                                    // <BTNConnectWallet className="btn-connect-wallet" />
                                    <div className="custom-btn-connect">
                                        <BTNPrimary
                                            className="btn-connect-wallet"
                                            title="Connect Wallet"
                                            onClick={() => {
                                                dispatch(
                                                    actionToggleModal({
                                                        visible: true,
                                                        title: 'Select a Wallet',
                                                        width: 678,
                                                        data: <WalletModal />,
                                                    }),
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="right-lock-tier">
                            <div className="item-action-token">
                                <div className="hint-lock">LOCKED-IN</div>
                                <div className="num-lock">{lockedIn.toLocaleString() ?? 0}</div>
                                <BTNPrimary
                                    className={`${lockedIn === 0 ? 'disable' : ''}`}
                                    title="Unlock"
                                    onClick={onUnlock}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <Row className="row-content mt-top-your-tier">
                    <Col xs={24} md={24} className="tie-mobile">
                        <Row>
                            <Col xs={24} md={24} className="header-title">
                                <Row>
                                    <Col xs={12} md={12} className="name fw-bold">
                                        Your tier
                                    </Col>
                                    <Col xs={12} md={12} className="tier-badge">
                                        <div>{tierMobile()}</div>
                                    </Col>
                                </Row>
                                <Row className="tier-process-bar">
                                    <Row className="tier-process-complete" style={{ width: `${processPercent()}` }} />
                                </Row>

                                <Row className="tier-list-mobile">
                                    {tierListMobile.map((tier) => {
                                        return (
                                            <Row key={tier.label} className="card-tier">
                                                <Col xs={24}>
                                                    <Row className="card-tier-header">
                                                        <Col xs={16}>
                                                            <p className="fw-bold">{tier.label}</p>
                                                            <p className="fw-bold">{tier.value.toLocaleString()} ILA</p>
                                                        </Col>
                                                        <Col xs={8} className="custom-badge">
                                                            <div className="badge">{tier.badge}</div>
                                                        </Col>
                                                    </Row>
                                                    {tier.info.map((item, idx) => (
                                                        <Row className="card-tier-content" key={item}>
                                                            <Col xs={16}>
                                                                <div className="title">{tier.description[idx]}</div>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <div className="result">{item}</div>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="tier">
                        <Row>
                            <span className="fs-large fw-bold">Your Tier</span>
                        </Row>
                        <Row className="tier-process-bar">
                            <Row className="tier-process-complete" style={{ width: `${processPercent()}` }} />
                        </Row>
                        <Row className="tier-list">
                            {tierList.map((tier) => {
                                return (
                                    <Col key={tier.label}>
                                        <Row>
                                            {tier.badge && <div className="tier-badge">{tier.badge}</div>}
                                            <Col>
                                                <span className="fs-suppersmal fw-bold tier-label">{tier.label}</span>
                                                <span className="fs-regular fw-medium tier-value">
                                                    {tier.value.toLocaleString()} ILA
                                                </span>
                                            </Col>
                                        </Row>
                                        {tier.info.map((info, idx) => {
                                            const key = idx + 1;
                                            return (
                                                <span
                                                    key={`${tier.label}-${key}`}
                                                    className={`fs-regular fw-medium tier-info-title
                                                ${tier.badge && 'tier-info-value'}`}
                                                >
                                                    {info}
                                                </span>
                                            );
                                        })}
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
                <Row className="row-content">
                    <Col lg={24}>
                        <Row>
                            <Col xs={12} className="status">
                                <span className="fs-large fw-bold">Your status</span>
                            </Col>
                            <Col lg={7} xs={12}>
                                <Row>
                                    <Col lg={12} className="status-info">
                                        <div className="status-info-label">
                                            <span className="fs-suppersmal fw-bold">CURRENT PENALTY</span>
                                        </div>
                                        <div className="status-info-text">
                                            <span className="fs-regular fw-bold">{getCurrentPenalty()}%</span>
                                        </div>
                                    </Col>
                                    <Col lg={12} className="status-info">
                                        <div className="status-info-label">
                                            <span className="fs-suppersmal fw-bold">LAST DEPOSIT</span>
                                        </div>
                                        <div className="status-info-text">
                                            <span className="fs-regular fw-bold">{getLastDeposit()} day(s) ago</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="claimer-info">
                            <span className="fs-large fw-bold claimer-info-label">Disclaimer</span>
                            <span className="fs-regular fw-regular claimer-info-text">
                                To earn the Tiers, users will be required to lock-in ILA tokens on the INFINITE LAUNCH
                                platform. Early unlocking will trigger a penalty burn, but the amount burned depends on
                                how many days have passed since your most recent ILA lock-in.
                            </span>
                            {/* <span className="fs-regular fw-regular claimer-info-text">
                                In case you do not belong to any tier, the Diamond Hand vesting pool will be available
                                soon for everyone, on a FCFS basis.
                            </span>
                            <span className="fs-regular fw-regular claimer-info-text">
                                Locking ILA to acquire Tiers will not offer APR like staking, it gives investors
                                allocations. Based on your tiers, you will be eligible to receive different bonus APRs
                                in staking pools.
                            </span> */}
                            {disclaimerList.map((disclaimer, idx) => {
                                const key = idx + 1;
                                return (
                                    <Row className="claimer-list" key={key}>
                                        <span className="fs-regular fw-medium claimer-info-label">
                                            {disclaimer.label}
                                        </span>
                                        <span className="fs-regular fw-medium claimer-info-label">
                                            {disclaimer.value}
                                        </span>
                                    </Row>
                                );
                            })}
                            <span className="fs-regular fw-regular claimer-info-text">
                                The time count starts at the latest deposit date.
                                <br />
                                For example, if you locked 20,000 ILA on Monday, and on Friday you staked another 80,000
                                ILA, the amount of days will be counted from Friday instead of Monday.
                                <br />
                                Bronze tier is the minimum requirement for investors to participate in any IDO.
                                <br />
                            </span>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

export default MyWallet;
