import Web3 from 'web3';
import contractSkeleton from 'src/contracts/skeleton_nft.json';
import { isMainnet } from 'src/configs/Configs.env';
import { AddressZero } from '@ethersproject/constants';
import { isAddress } from 'src/utils/validate';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library;
}

export const getContractSkeletonABI = () => {
    return contractSkeleton.abi;
};

export const getContractSkeletonAddress = () => {
    return isMainnet ? contractSkeleton.address.mainnet : contractSkeleton.address.testnet;
};

export const getContractSkeleton = (contractAddress: any) => {
    const contractABI: any = contractSkeleton.abi;
    const provideUrl = isMainnet
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddress);
    return contract;
};

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`);
    }
    return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export const getContractV2 = (address: any, abi: any) => {
    const provideUrl = isMainnet
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(abi, address);
    return contract;
};
