import BigNumber from 'bignumber.js';
import http from 'src/services/http';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { ENVS } from '../../../configs/Configs.env';
import contractILA from '../../../contracts/ila.json';
import contractLockTier from '../../../contracts/LockTierV2.json';

declare global {
    interface Window {
        ethereum: any;
    }
}

export const onRequestConnect = async () => {
    let [userAddress] = await window.ethereum.enable();
    if (userAddress === '') {
        await window.ethereum.request({
            method: 'eth_requestAccounts',
            params: [{ eth_accounts: {} }],
        });
        const [newAddress] = await window.ethereum.enable();
    }
};

export const getILABalanceV2 = async (address: string) => {
    const contractABI: any = contractILA.abi;
    const contractAddr: any = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const provideUrl = ENVS.REACT_APP_IS_MAINNET
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);

    try {
        const ilaInWallet = await contract.methods.balanceOf(address).call();
        const lockInfo = await contract.methods.checkBalanceLocked(address).call();
        return { ilaInWallet, lockInfo };
    } catch (error) {
        console.log('lockInfo error: ', error);
        throw new Error();
    }
};

export const getILABalance = async (address: string) => {
    const contractABI = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABI, provider.getSigner(0));

    try {
        const ilaInWallet = await contract.balanceOf(address);
        const lockInfo = await contract.checkBalanceLocked(address);
        return { ilaInWallet, lockInfo };
    } catch (error) {
        console.log('lockInfo error: ', error);
        throw new Error();
    }
};

export const apiGetAccountLockTierInfo = async (address: string, contractAddr: string) => {
    const contractABI = contractLockTier;
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABI, provider.getSigner(0));
    try {
        const accountInfo = await contract.getAccountInfo(address);
        return { accountInfo };
    } catch (error) {
        console.log('getAccountLockTierInfo error: ', error);
        throw new Error();
    }
};

export const createLockV2 = async (amount: number, contractAddr: string) => {
    const contractABI = contractLockTier;
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABI, provider.getSigner(0));

    try {
        const amountLock = ethers.utils.parseEther(amount.toString());
        const tx = await contract.stake(amountLock);

        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const unlockV2 = async (amount: number, contractAddr: string) => {
    const contractABI = contractLockTier;
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABI, provider.getSigner(0));

    try {
        const amountUnLock = ethers.utils.parseEther(amount.toString());
        const tx = await contract.unstake(amountUnLock);
        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const claim = async (contractAddr: string) => {
    const contractABI = contractLockTier;
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABI, provider.getSigner(0));

    try {
        const tx = await contract.claimReward();
        return tx;
    } catch (error) {
        console.log('claim error: ', error);
        throw error;
    }
};

export const approveILA = async (contractAddress: string) => {
    const contractABIILA = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABIILA, provider.getSigner(0));

    try {
        const tx = await contract.approve(contractAddress, ethers.constants.MaxUint256);
        return tx;
    } catch (error: any) {
        console.log('error: ', error);
    }
};

export const checkApproveILA = async (contractAddress: string, addressUser: string) => {
    const contractABIILA = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABIILA, provider.getSigner(0));

    try {
        const allowance = await contract.allowance(addressUser, contractAddress);
        const userAllowance = new BigNumber(allowance.toString()).div(10 ** 18).toNumber();
        return userAllowance;
    } catch (error: any) {
        console.log('error: ', error);
    }
};

export const createLock = async (amount: number) => {
    const contractABI = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABI, provider.getSigner(0));

    try {
        const amoutLock = ethers.utils.parseEther(amount.toString());
        const tx = await contract.createLock(amoutLock);

        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const unlock = async () => {
    const contractABI = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const contract = new ethers.Contract(contractAddr, contractABI, provider.getSigner(0));

    try {
        const tx = await contract.withdrawVested();

        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const apiGetProfileInfo = async (isCheckKYC: number, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/profile?check-kyc=${isCheckKYC}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateAccount = async (data: any, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/update`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetStatusKYCFromBlockPass = (kycRefId: string) => {
    const apiKey = ENVS.REACT_APP_BLOCK_PASS_API_KEY;
    const apiURL = `https://kyc.blockpass.org/kyc/1.0/connect/${ENVS.REACT_APP_BLOCK_PASS_CLIENT_ID}/refId/${kycRefId}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${apiKey}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateOtherWallet = async (data: { Wallets: any }, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/update`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
