import styled, { ITheme } from 'styled-components';
import { Row } from 'antd';

export const Wrapper = styled.div`
    background: #1b1f27;
    padding: 30px;
    .ant-drawer-body {
        padding: 0;
    }

    .tabs-menu-mobile {
        margin-top: 12px;
        display: flex;
        border-bottom: 1px solid ${({ theme }: { theme: ITheme }) => theme.border1};
        .item-menu-mobile {
            color: #e6e8ec;
            padding: 7px 20px;
            &.active {
                background: #3d3d3d;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }
    }
    .box-dropdown {
        width: 100%;
        .list-btn-account {
            margin-top: 30px;
        }
        .row-dropdown {
            .col-dropdown {
                margin-top: 30px;
                width: 100%;
                .guaranteed-allocation {
                    .ant-image {
                        margin-top: 30px;
                        width: 100%;
                    }

                    .text-guaranteed {
                        margin-top: 8px;
                        color: #e6e8ec;
                    }
                    .hint-guaranteed {
                        margin-top: 8px;
                        color: #777e91;
                    }
                    .how-it-work {
                        margin-top: 28px;
                        color: #4bc9f0;
                        cursor: pointer;
                    }
                }
                .item-dropdown {
                    display: flex;
                    transition: 0.3s;

                    &.mt-item {
                        margin-top: 30px;
                    }

                    .item-text {
                        cursor: pointer;
                        margin-left: 8px;
                        .text {
                            color: ${({ theme }: { theme: ITheme }) => theme.text6};
                        }
                        .hint {
                            margin-top: 2px;
                            color: ${({ theme }: { theme: ITheme }) => theme.dark6};
                        }
                    }
                    &:hover {
                        transform: scale(1.01);
                    }
                }

                .box-upgrade-tier {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(75, 201, 240, 1)' stroke-width='1' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square' /%3e%3c/svg%3e");
                    border-radius: 4px;
                    padding: 18px 20px;
                    .left {
                        color: #4bc9f0;
                        cursor: pointer;
                    }
                    .right {
                        color: #4bc9f0;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        svg {
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
`;
