import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { NetworkConnector } from './Configs.networkConnector';
import RPC from './Configs.rpc';
import { SUPPORTED_NETWORKS } from './Configs.networks';

export const network = new NetworkConnector({
    defaultChainId: 1,
    urls: RPC,
});

const supportedChainIds = Object.values(ChainId) as number[];

export const injected = new InjectedConnector({
    supportedChainIds,
});

export interface WalletInfo {
    connector?: (() => Promise<AbstractConnector>) | AbstractConnector | any;
    name: string;
    iconName: string;
    description: string;
    href: string | null;
    color: string;
    primary?: true;
    mobile?: true;
    mobileOnly?: true;
    supportNetworks?: any;
    chromeUrl?: string;
    website?: string;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    INJECTED: {
        connector: injected,
        name: 'Injected',
        iconName: 'metamask.svg',
        description: 'Injected web3 provider.',
        href: null,
        color: '#010101',
        primary: true,
    },
    METAMASK: {
        connector: injected,
        name: 'MetaMask',
        iconName: 'metamask.svg',
        description: 'Easy-to-use browser extension.',
        href: null,
        color: '#E8831D',
        supportNetworks: [
            SUPPORTED_NETWORKS[ChainId.MAINNET],
            SUPPORTED_NETWORKS[ChainId.BSC],
            SUPPORTED_NETWORKS[ChainId.MATIC],
            SUPPORTED_NETWORKS[ChainId.FANTOM],
        ],
    },
    PHANTOM: {
        href: null,
        color: '#315CF5',
        iconName: 'phantom.svg',
        description: 'Open in Solana Wallet app.',
        name: 'Phantom',
        website: 'https://phantom.app',
        chromeUrl: 'https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa',
        supportNetworks: [SUPPORTED_NETWORKS[ChainId.FANTOM]], // SUPPORTED_NETWORKS[ChainId.FANTOM], SUPPORTED_NETWORKS[ChainId.FANTOM_TESTNET]
    },
    Binance: {
        connector: new BscConnector({ supportedChainIds: [56] }),
        name: 'Binance',
        iconName: 'binance.svg',
        description: 'Login using Binance hosted wallet',
        href: null,
        color: '#F0B90B',
        mobile: true,
        supportNetworks: [SUPPORTED_NETWORKS[ChainId.BSC]],
    },
    WALLET_CONNECT: {
        connector: new WalletConnectConnector({
            rpc: RPC,
            bridge: 'https://bridge.walletconnect.org',
            qrcode: true,
            supportedChainIds: [
                1, // mainnet
                3, // ropsten
                4, // rinkeby
                5, // goreli
                42, // kovan
                250, // fantom
                4002, // fantom testnet
                137, // matic
                80001, // matic testnet
                100, // xdaiW
                56, // binance smart chain
                97, // binance smart chain testnet
                1287, // moonbase
                43114, // avalanche
                43113, // fuji
                128, // heco
                256, // heco testnet
                1666600000, // harmony
                1666700000, // harmony testnet
                66, // okex testnet
                65, // okex testnet
                42161, // arbitrum
                42220, // celo
                11297108109, // palm
                1285, // moonriver
            ],
            // pollingInterval: 15000,
        }),
        name: 'WalletConnect',
        iconName: 'walletconnect.svg',
        description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
        href: null,
        color: '#4196FC',
        mobile: true,
    },
    WALLET_LINK: {
        connector: async () => {
            const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
            return new WalletLinkConnector({
                url: RPC[ChainId.MAINNET],
                appName: 'SushiSwap',
                appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
            });
        },
        name: 'Coinbase Wallet',
        iconName: 'coinbase.svg',
        description: 'Use Coinbase Wallet app on mobile device',
        href: null,
        color: '#315CF5',
    },
    COINBASE_LINK: {
        name: 'Open in Coinbase Wallet',
        iconName: 'coinbase.svg',
        description: 'Open in Coinbase Wallet app.',
        href: 'https://go.cb-w.com',
        color: '#315CF5',
        mobile: true,
        mobileOnly: true,
    },
    SOLANA: {
        href: null,
        color: '#315CF5',
        iconName: 'solana.svg',
        description: 'Open in Solana Wallet app.',
        name: 'Solana',
        website: 'https://phantom.app',
        chromeUrl: '',
        supportNetworks: [
            SUPPORTED_NETWORKS[ChainId.MAINNET],
            SUPPORTED_NETWORKS[ChainId.BSC],
            SUPPORTED_NETWORKS[ChainId.MATIC],
            SUPPORTED_NETWORKS[ChainId.FANTOM],
        ],
    },
};
