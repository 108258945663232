import React, { memo } from 'react';
import { Wrapper } from 'src/modules/Connect/Connect.styled';
import { Row } from 'antd';
import useActiveWallet from 'src/hooks/useActiveWallet';
import { BTNPrimary } from 'src/components/Button';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import Typography from 'src/components/Typography';
import Copy from 'src/components/Copy/Copy';
import { ellipsisCenter } from 'src/utils/ellipsis';
import ExternalLink from 'src/components/ExternalLink';
import { getExplorerLink } from 'src/functions';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import { WalletDisconnectButton } from '@solana/wallet-adapter-ant-design';
import { useWallet } from '@solana/wallet-adapter-react';
import { ACTION_TYPES } from 'src/components/Header/redux/Wallet.action';

interface IActiveWalletProps {
    openConnectWalletModal: () => void;
}

const ActiveWallet = ({ openConnectWalletModal }: IActiveWalletProps & any) => {
    const activeWallet = useActiveWallet();
    const { publicKey, wallet } = useWallet();
    const dispatch = useDispatch();
    const { account, chainId, deactivate } = useActiveWeb3React();
    return (
        <Wrapper>
            <Row className="header-row">
                {!!activeWallet && !!activeWallet.name && (
                    <p className="fw-bold fs-regular title">{`Connected with ${
                        wallet?.name ? wallet.name : activeWallet.name
                    }`}</p>
                )}
                <BTNPrimary className="btn-change" title="Change" onClick={openConnectWalletModal} />
            </Row>
            {!!account && (
                <p className="fw-bold fs-regular addressText">{ellipsisCenter({ str: account, limit: 10 })}</p>
            )}
            <Row style={{ marginTop: 15 }} className="justify-between">
                <Row>
                    <ExternalLink
                        color="blue"
                        startIcon={<ExternalLinkIcon width={16} height={16} />}
                        href={chainId && getExplorerLink(chainId, account || '', 'address')}
                    >
                        <Typography variant="sm">View on explorer</Typography>
                    </ExternalLink>
                    <div style={{ width: 20 }} />
                    <Copy toCopy={account || ''}>
                        <Typography variant="sm">Copy Address</Typography>
                    </Copy>
                </Row>
                {publicKey ? (
                    <WalletDisconnectButton
                        className="btn-solana"
                        onClick={() => {
                            dispatch(actionCloseModal());
                        }}
                    />
                ) : (
                    <BTNPrimary
                        className="btn-change btn-border"
                        title="Disconnect"
                        onClick={() => {
                            typeof deactivate === 'function' && deactivate();
                            localStorage.removeItem('connector');
                            dispatch(actionCloseModal());
                            dispatch({
                                type: ACTION_TYPES.SET_CHAIN_ID,
                                payload: -1,
                            });
                        }}
                    />
                )}
            </Row>
        </Wrapper>
    );
};

export default memo(ActiveWallet);
