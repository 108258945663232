import React, { memo } from 'react';
import { Form } from 'antd';
import BTNPrimary from 'src/components/Button/Button.primary';
import { TextInput } from 'src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from 'src/modules/Auth/Auth.styled';
import { actionSetSignin } from 'src/modules/Auth/Auth.actions';
import { loadingSelector, signinSelector } from 'src/modules/Auth/Auth.selector';
import validator from 'src/components/ReduxForm/validator';
import GradientText from 'src/components/GradiendText';
import withAuth from './Auth.enhance';

interface ISigninProps {
    openSignupModal: () => void;
    openForgotModal: () => void;
    onSignin: () => void;
}

const Signin = ({ openSignupModal, openForgotModal, onSignin }: ISigninProps & any) => {
    const dispatch = useDispatch();
    const { email, password, error } = useSelector(signinSelector);
    const loading = useSelector(loadingSelector);
    const [form] = Form.useForm();
    const formError = React.useMemo(() => {
        const _email = validator.email({ value: email });
        const _pass = validator.password({ value: password || '' });
        return {
            email: _email,
            pass: _pass,
            disabled: !!_email || !!_pass || loading,
        };
    }, [email, password, loading]);

    return (
        <Wrapper>
            <Form className="form" form={form} name="vertical">
                <GradientText className="fw-bold disable-pointer title header-title">Sign in</GradientText>
                <TextInput
                    value={email}
                    onUserInput={(text) => {
                        dispatch(actionSetSignin({ email: text }));
                    }}
                    fontSize="12"
                    placeholder="Email address"
                    className="text-input"
                    label="EMAIL"
                    type="email"
                    error={formError.email}
                />
                <TextInput
                    value={password}
                    onUserInput={(text) => {
                        dispatch(actionSetSignin({ password: text }));
                    }}
                    fontSize="12"
                    placeholder="Password"
                    className="text-input"
                    label="PASSWORD"
                    type="password"
                    error={formError.pass}
                />
                <div className="fs-suppersmal fw-suppermedium forgot-text" onClick={openForgotModal}>
                    Forgot password
                </div>
                {!!error && <p className="fs-medium error">{error}</p>}
                <BTNPrimary
                    loading={loading}
                    disabled={formError.disabled}
                    className="button btn-signin"
                    title="Signin"
                    onClick={onSignin}
                />
                <div className="fs-suppersmal fw-suppermedium disable-pointer signup-prefix-text">
                    Don’t have an account?{' '}
                    <span className="signup-text" onClick={openSignupModal}>
                        Signup
                    </span>
                </div>
            </Form>
        </Wrapper>
    );
};

export default withAuth(memo(Signin));
