import React, { memo } from 'react';
import { Form } from 'antd';
import BTNPrimary from 'src/components/Button/Button.primary';
import { TextInput } from 'src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from 'src/modules/Auth/Auth.styled';
import TextSignup from 'src/modules/Auth/Auth.textSignup';
import { actionSetSignup } from 'src/modules/Auth/Auth.actions';
import { loadingSelector, signupSelector } from 'src/modules/Auth/Auth.selector';
import { CheckBoxIcon } from 'src/components/Icons';
import validator from 'src/components/ReduxForm/validator';
import withAuth from './Auth.enhance';
import GradientText from '../../components/GradiendText';

interface ISignupProps {
    openSigninModal: () => void;
    onSignup: () => void;
}

const Signup = ({ openSigninModal, onSignup }: ISignupProps & any) => {
    const dispatch = useDispatch();
    const { email, password, confirmPassword, error } = useSelector(signupSelector);
    const [form] = Form.useForm();
    const [accept, setAccept] = React.useState(false);
    const loading = useSelector(loadingSelector);
    const formError = React.useMemo(() => {
        const _email = validator.email({ value: email });
        const _pass =
            validator.password({ value: password }) ||
            validator.comparePass({ value: password, compareValue: confirmPassword });
        const _confirm =
            validator.password({ value: confirmPassword }) ||
            validator.comparePass({ value: confirmPassword, compareValue: password });
        return {
            email: _email,
            pass: _pass,
            confirm: _confirm,
            disabled: !!_email || !!_pass || !!_confirm || loading,
        };
    }, [email, password, confirmPassword, loading]);
    return (
        <Wrapper>
            <Form className="form" form={form} name="vertical">
                <GradientText className="fw-bold disable-pointer title header-title">Sign up</GradientText>
                <TextInput
                    value={email}
                    onUserInput={(text) => {
                        dispatch(actionSetSignup({ email: text }));
                    }}
                    fontSize="12"
                    placeholder="Email address"
                    className="text-input"
                    label="EMAIL"
                    type="email"
                    error={formError.email}
                />
                <TextInput
                    value={password}
                    onUserInput={(text) => {
                        dispatch(actionSetSignup({ password: text }));
                    }}
                    fontSize="12"
                    placeholder="Password"
                    className="text-input"
                    label="PASSWORD"
                    type="password"
                    error={formError.pass}
                />
                <TextInput
                    value={confirmPassword}
                    onUserInput={(text) => {
                        dispatch(actionSetSignup({ confirmPassword: text }));
                    }}
                    fontSize="12"
                    placeholder="Password"
                    className="text-input"
                    label="CONFIRM PASSWORD"
                    type="password"
                    error={formError.confirm}
                />
                <div className="wrap-term">
                    <CheckBoxIcon active={accept} onClick={() => setAccept((value) => !value)} />
                    <div className="wrap-term-text">
                        <p className="fs-small fw-medium disable-pointer term-prefix">
                            By signing up I agree that I’m 18 years of age or older, to the{' '}
                            <span className="fs-small fw-medium term-suffix">
                                User Agreements, Privacy Policy, Cookie Policy, E-Sign Consent.
                            </span>
                        </p>
                    </div>
                </div>
                {!!error && <p className="fs-medium error">{error}</p>}
                <BTNPrimary
                    loading={loading}
                    disabled={formError.disabled || accept === false}
                    className="button btn-signin"
                    title="Signup"
                    onClick={onSignup}
                />
                <div className="fs-suppersmal fw-suppermedium disable-pointer signup-prefix-text">
                    Already have an account?{' '}
                    <span className="signup-text" onClick={openSigninModal}>
                        Signin
                    </span>
                </div>
            </Form>
        </Wrapper>
    );
};

export default withAuth(memo(Signup));
