/* eslint-disable jsx-a11y/no-autofocus */
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row as AntRow } from 'antd';
import { BTNPrimary } from 'src/components/Button';
import GradiendText from 'src/components/GradiendText';
import styled, { ITheme } from 'styled-components';
import Row from 'src/components/Row';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { createLock, createLockV2, approveILA, checkApproveILA } from '../redux/Account.service';
import { getILABalanceAction, getAccountLockTierInfo } from '../redux/Account.action';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    p,
    span {
        color: ${({ theme }: { theme: ITheme }) => theme.text7};
        text-align: center;
    }

    .btn-primary {
        height: 40px;

        &.disable {
            background: ${({ theme }: { theme: ITheme }) => theme.text7};
            color: ${({ theme }: { theme: ITheme }) => theme.dark7};

            &:hover {
                cursor: not-allowed;
                transform: none;
            }

            &:active {
                cursor: not-allowed;
                opacity: 1;
            }
        }
    }

    .text-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 32px 0;
    }

    .tier-list {
        margin: 32px 0;
        justify-content: space-between;

        .tier-item {
            padding: 5px;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                border: 2px solid ${({ theme }: { theme: ITheme }) => theme.blue2};
                border-radius: 4px;
                padding: 3px;
            }
        }

        .tier-badge {
            display: flex;
            width: 48px;
            height: 48px;
            margin-right: 14px;
            border-radius: 24px;
            justify-content: center;
            align-items: center;
            background: ${({ theme }: { theme: ITheme }) => theme.dark2};
        }

        .tier-label {
            color: ${({ theme }: { theme: ITheme }) => theme.dark3};
        }

        .tier-value {
            color: ${({ theme }: { theme: ITheme }) => theme.text7};
        }

        .ant-col .ant-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 12px 0;
        }

        .btn-primary.disable {
            background: ${({ theme }: { theme: ITheme }) => theme.text7};
            color: ${({ theme }: { theme: ITheme }) => theme.dark7};

            &:hover {
                cursor: unset;
                transform: none;
            }
        }
    }
    .box-balance {
        display: flex;
        flex: 1;
        margin-bottom: 16px;
        justify-content: flex-end;
        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #c4c4c4;
        }
    }
    .box-content {
        display: flex;
        padding: 8px 12px;
        height: 52px;
        margin-bottom: 32px;
        background: #353945;
        width: 100%;
        border-radius: 12px;
        justify-content: space-between;
    }
    .box-title-head {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #fcfcfd;
        .input {
            width: 100%;
        }
    }
    .box-title-sub {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #3772ff;
        margin-top: 6px;
        margin-left: 16px;
        margin-right: 16px;
        cursor: pointer;
    }
`;

const LockTierModal = React.memo((props: any) => {
    const { account } = useActiveWeb3React();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [amountIla, setAmountIla] = useState<any>(0);
    const [allowance, setAllowance] = useState<number>(0);
    let { tierList, contractAddress } = props;
    tierList = tierList.filter((tier: any) => tier.badge);
    const [selected, setSelected] = useState<any>(null);
    const [textButton, setTextButton] = useState<any>('Lock Tier');
    const {
        ilaBalance,
        lockedIn,
        lastDeposit,
        currentPenalty,
        apr,
        tierAPR,
        amount,
        reward,
        lastStakeTime,
        lastUpdate,
    }: any = useSelector((state: any) => state.account);
    const checkApproveIla = async () => {
        if (account) {
            const contractAllowance = await checkApproveILA(contractAddress, account);
            if (contractAllowance) {
                setAllowance(contractAllowance);
                setTextButton('Lock Tier');
            } else {
                setTextButton('Approve');
            }
        }
    };

    const getData = () => {
        dispatch(getILABalanceAction(account));
        dispatch(getAccountLockTierInfo(account, contractAddress));
    };

    const onButtonClick = async () => {
        if (amountIla <= 0) {
            return;
        }
        setLoading(true);
        const result = await createLockV2(amountIla, contractAddress);

        try {
            if (!result) {
                throw new Error();
            }

            dispatch(
                addToast({
                    type: TOAST_TYPES.WAITING,
                    title: 'Waiting!',
                    description: 'Your transaction processing.',
                }),
            );

            await result.wait();

            dispatch(
                addToast({
                    type: TOAST_TYPES.SUCCESS,
                    title: 'Well done!',
                    description: 'Your transaction successfully.',
                }),
            );

            if (account) {
                getData();
            }
            setLoading(false);
            dispatch(
                actionToggleModal({
                    visible: false,
                    data: null,
                }),
            );
        } catch (error: any) {
            console.log('error: ', error);
            setLoading(false);
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Oh snap!',
                    description: 'Change a few things up and try submitting again.',
                }),
            );
        }
    };
    function changeCurrency(e: any) {
        if (e.target.value.match('^[0-9]*[.,]?[0-9]{0,18}$') != null) {
            setAmountIla(e.target.value);
        }
        e.preventDefault();
    }

    const onApprove = async () => {
        try {
            let tx = null;
            if (!window.ethereum) {
                throw new Error('Your browser is not support');
            }
            setLoading(true);
            tx = await approveILA(contractAddress);

            if (tx) {
                dispatch(
                    addToast({
                        type: TOAST_TYPES.WAITING,
                        title: 'Waiting!',
                        description: 'Your transaction processing.',
                    }),
                );

                await tx.wait();

                dispatch(
                    addToast({
                        type: TOAST_TYPES.SUCCESS,
                        title: 'Well done!',
                        description: 'Your transaction successfully.',
                    }),
                );
            }
            checkApproveIla();
            getData();
            setLoading(false);
        } catch (error: any) {
            let message = error.data?.message === undefined ? error?.message : error.data?.message;
            setLoading(false);
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Oh snap!',
                    description: message,
                }),
            );
            console.log('error: ', error);
        }
    };
    useEffect(() => {
        checkApproveIla();
    }, [allowance]);

    return (
        <Wrapper>
            <GradiendText className="fs-verylarge fw-bold">Balance</GradiendText>
            <span style={{ marginTop: '20px' }} className="fs-large fw-bold">
                {ilaBalance.toLocaleString()} ILA
            </span>
            <AntRow className="tier-list">
                {tierList.map((tier: any) => {
                    return (
                        <Col
                            className={`tier-item ${selected?.label === tier.label ? 'selected' : ''}`}
                            span={11}
                            key={tier.label}
                            onClick={() => {
                                setSelected(tier);
                                setAmountIla(tier.value - amount > 0 ? tier.value - amount : 0);
                            }}
                        >
                            <Row>
                                {tier.badge && <div className="tier-badge">{tier.badge}</div>}
                                <Col>
                                    <span className="fs-suppersmal fw-bold tier-label">{tier.label}</span>
                                    <span className="fs-regular fw-medium tier-value">
                                        {tier.value.toLocaleString()} ILA
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    );
                })}
            </AntRow>
            <div className="box-content">
                <p className="box-title-head">
                    <input
                        className="input"
                        type="text"
                        pattern="^[0-9]*[.,]?[0-9]{0,18}$"
                        min="0"
                        autoFocus
                        placeholder="0.00"
                        value={amountIla}
                        onChange={changeCurrency}
                    />
                </p>
                <div
                    className="box-title-sub"
                    onClick={() => {
                        setAmountIla(`${Math.floor(ilaBalance * 10000) / 10000}`);
                    }}
                >
                    MAX
                </div>
            </div>
            <BTNPrimary
                // className={`${amountIla > 0 && amountIla <= ilaBalance && allowance > 0 ? '' : 'disable'}`}
                title={textButton}
                loading={loading}
                disabled={loading}
                onClick={() => {
                    if (!allowance) {
                        onApprove();
                    } else {
                        onButtonClick();
                    }
                }}
            />
        </Wrapper>
    );
});

export default LockTierModal;
