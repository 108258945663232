import React, { Suspense } from 'react';
import { BackTop, Image } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { IRouteProps } from 'src/modules';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { accessTokenSelector } from 'src/modules/Auth/Auth.selector';
import { COMPONENT_IDS } from 'src/app/App.constant';
import enhance from 'src/modules/MainRoute/MainRoute.enhance';
import { IProps } from 'src/modules/MainRoute/MainRoute.inteface';
import Toast from 'src/components/Toast/Toast';
import scrollToTopImage from 'src/assets/image/scroll-to-top.png';
import { actionGetProfileInfo } from '../Accounts/redux/Account.action';
import { actionGetPriceIla } from '../StakeFarming/redux/StakeFarming.action';

const Styled = styled.div<{ height: number }>`
    min-height: ${({ height }) => `${height}px`};
    // margin-top: 80px;

    .ant-back-top {
        opacity: 0.7;
    }
`;

const MainRoute = (props: IProps & any) => {
    const dispatch = useDispatch();
    const { routes } = props;
    const accessToken = useSelector(accessTokenSelector);
    React.useEffect(() => {
        dispatch(actionGetPriceIla('ILA-USDT'));
        if (!accessToken) {
            return;
        }
        window.token = accessToken;

        dispatch(actionGetProfileInfo(0, window.token));
    }, [accessToken]);

    const [height, setHeight] = React.useState<any>(0);
    const getContentHeight = () => {
        const search = window.location.pathname.toString().search('iframe/nft-ido/');
        if (search !== -1) {
            return;
        }
        const headerElement = document.getElementById(COMPONENT_IDS.HEADER) as HTMLFormElement;
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const footerElement = document.getElementById(COMPONENT_IDS.FOOTER) as HTMLFormElement;
        const footerHeight = footerElement ? footerElement.clientHeight : 0;
        const windowHeight = window.innerHeight;
        setHeight(windowHeight - headerHeight - footerHeight);
    };
    React.useEffect(() => {
        getContentHeight();
    }, []);

    if (!routes || routes.length === 0) return null;
    return (
        <Styled height={height} style={{ marginTop: height === 0 ? height : 80 }}>
            <Toast />
            <Switch>
                <Suspense fallback={null}>
                    {routes.map((route: IRouteProps) => (
                        <Route {...route} key={route.path} />
                    ))}
                </Suspense>
            </Switch>
            <BackTop>
                <div className="scroll-to-top">
                    <Image preview={false} src={scrollToTopImage} />
                </div>
            </BackTop>
        </Styled>
    );
};

export default enhance(React.memo(MainRoute));
