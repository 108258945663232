import React, { useState } from 'react';
import styled, { ITheme } from 'styled-components';
import { Form } from 'antd';
import { TextInput } from 'src/components/Input';
import { BTNPrimary } from 'src/components/Button';
import { userInfoSelector } from 'src/modules/Auth/Auth.selector';
import { useDispatch, useSelector } from 'react-redux';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import { validator } from 'src/components/ReduxForm';
import { actionUpdateOtherWallet } from '../redux/Account.action';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-top: -30px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }: { theme: ITheme }) => theme.dark1};
    > div {
        width: 100%;
    }
    .form {
        width: 100%;
        .submit-address {
            font-size: 32px;
            font-weight: 700;
        }
        .btn-submit {
            margin-top: 32px;
        }
        .text-input {
            margin-top: 32px;
        }
    }
`;

const ModelAddOtherWallet = ({ name }: { name: string }) => {
    const [form] = Form.useForm();
    const [address, setAddress] = useState('');
    const [error, setError] = useState<any>(null);
    const userInfoEs = useSelector(userInfoSelector);
    const { userInfo }: any = useSelector((state: any) => state.account);

    const dispatch = useDispatch();
    const token = userInfoEs.auth.accessToken;
    const handleAddNetwork = (iaddress: string) => {
        if (!iaddress) {
            setError('Please enter address');
            return;
        }
        setError(null);
        const arr = userInfo.Wallets ? userInfo.Wallets : [];
        arr.push({
            network: name,
            address: iaddress,
        });
        dispatch(
            actionUpdateOtherWallet(
                {
                    Wallets: arr,
                },
                token,
            ),
        );
        dispatch(actionCloseModal());
    };

    return (
        <Wrapper>
            <Form className="form" form={form} name="vertical">
                <TextInput
                    value={address}
                    onUserInput={(text) => {
                        setAddress(text);
                    }}
                    fontSize="12"
                    placeholder={`Enter your ${name} wallet address`}
                    className="text-input"
                    label={`YOUR  ${name.toUpperCase()} WALLET ADDRESS`}
                    type="text"
                    error={error}
                />
                <BTNPrimary
                    className="button btn-submit"
                    title="Submit Adress"
                    onClick={() => {
                        handleAddNetwork(address);
                    }}
                />
            </Form>
        </Wrapper>
    );
};

export default ModelAddOtherWallet;
